import {
    Box,
    SimpleGrid,
    Text,
    Button,
    HStack,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Select, 
    Spacer,
    Divider,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    useSteps
} from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useSearchParams } from 'react-router-dom';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import ModalStepper from './ModalStepper';
import { check_keap_appointment, create_convo_email, create_appointment, fetch_appointments, get_all_appointments, map_appointment_loading } from '../apis/apiRequests';

const backend_url = process.env.REACT_APP_BACKEND_URL;

const steps = [
    { title: 'Filters', description: 'by days created since' },
    { title: 'Appointments', description: 'Select an appointment' },
    { title: 'Map', description: 'Map to corresponding fields' },
]

function date_by_subtracting_days(date, days) {
    return new Date(
        date.getFullYear(), 
        date.getMonth(), 
        date.getDate() - days,
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
    );
}

export default function AppointmentModal({
    onAppointmentClose,
    isAppointmentOpen,
    appointmentTab,
    fetchAppointmentLoading,
    keapAppointments,
    setKeapAppointments,
    selectedAppointment,
    setAppointmentTab,
    uploadAppointmentLoading,
    setSelectedAppointment,
    mappedAppointments,
    setMappedContacts,
    setMappedAppointments,
    setFetchAppointmentLoading,
    setUploadAppointmentLoading,
    setMapAppointmentLoading,
    mapAppointmentLoading
}) {
    const [searchParams] = useSearchParams();
    const toast = useToast();

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    //loading states
    const [checkAppointmentLoading, setCheckAppointmentLoading] = useState(false);
    const [mappAllLoading, setMapAllLoading] = useState(false);

    //value states
    const [ghlContact, setGhlContact] = useState(null);
    const [fullAppointment, setFullAppointment] = useState(null);
    const [appointment, setAppointment] = useState(null);
    const [mapAllLink, setMapAllLink] = useState("https://api.infusionsoft.com/crm/rest/v1/appointments");

    //appointment modal tab
    const appointmentTabNext = (selected = null) => {
        // if(contactTab < 4) setContactTab(prev => prev + 1);

        if(appointmentTab === 0){
            let session = JSON.parse(localStorage.getItem("ghl-keap"))

            var select = document.getElementById('filter-days');
            var selected_value = select.options[select.selectedIndex].value;
            console.log("filter value", selected_value);

            let endpoint_hit;
            if(selected_value === "all"){
                endpoint_hit = "https://api.infusionsoft.com/crm/rest/v1/appointments?limit=10";
                setMapAllLink("https://api.infusionsoft.com/crm/rest/v1/appointments");
            } else {
                let since_date = date_by_subtracting_days(new Date(), Number(selected_value)).toISOString();
                endpoint_hit = `https://api.infusionsoft.com/crm/rest/v1/appointments?since_sent_date=${since_date}&limit=10`;
                setMapAllLink(`https://api.infusionsoft.com/crm/rest/v1/appointments?since_sent_date=${since_date}`);
            }

            console.log(endpoint_hit);

            const config = {
                locationId: session.ghl_token.locationId,
                keap_code: searchParams.get("code"),
                uri: window.location.origin + window.location.pathname,
                // refresh_token: session.keap_token.refresh_token,
                endpoint: endpoint_hit
            }

            setFetchAppointmentLoading(true);
            fetch_appointments(config)
            .then((result) => {
                result = result.data;
                console.log(result);
                if(result.success){;
                    localStorage.setItem('ghl-keap', JSON.stringify({
                        ...session,
                        keap_token: result.keap_token
                    }))
                    // let filteredAppointments = null;
                    // if(mappedAppointments){
                    //     filteredAppointments = result.keap_appointments.appointments.filter(appointment => {
                    //         const appointment_id = appointment.id;
    
                    //         return !mappedAppointments.some(mapped_appointment =>
                    //             mapped_appointment.id === appointment_id
                    //         );
                    //     });
                    //     result.keap_appointments.appointments = filteredAppointments;
                    // }
                    
                    setKeapAppointments(result.keap_apps);
                    setAppointmentTab(prev => {
                        setActiveStep(prev + 1)
                        return prev + 1
                    })
                }
            })
            .catch(e => {
                console.log(e.message);
            })
            .finally(() =>{
                setFetchAppointmentLoading(false);
            })

        } else if (appointmentTab === 1){
            let session = JSON.parse(localStorage.getItem("ghl-keap"))
            console.log(selected);
            const payload = {
                "locationId": session.ghl_token.locationId,
                "appointment": selected
            }

            setCheckAppointmentLoading(true);
            check_keap_appointment(payload)
            .then((result) => {
                if(result.data){
                    result = result.data;
                    if(result.success){
                        console.log(result);
                        setGhlContact(result.ghl_contact);
                        setFullAppointment(result.appointment);
                        setAppointment(selected);
                        toast({
                            title: 'Success!',
                            description: "Appointment Verified.",
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                        })
    
                        var key_value_pair = Object.keys(selected).map((key) => [key, selected[key]]);
                        console.log(key_value_pair);
                        
                        setSelectedAppointment(key_value_pair);
                        setAppointmentTab(prev => {
                            setActiveStep(prev + 1)
                            return prev + 1
                        });
                    }else {
                        toast({
                            title: 'Warning',
                            description: "Invalid Appointment",
                            status: 'warning',
                            duration: 9000,
                            isClosable: true,
                        })
                    }
                } else {
                    toast({
                        title: 'Warning',
                        description: "Invalid Appointment",
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            })
            .catch(e => {
                console.log(e.message);
                toast({
                    title: 'Failed!.',
                    description: e.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            })
            .finally(() =>{
                setCheckAppointmentLoading(false);
            })

        } else if (appointmentTab === 2){
            let session = JSON.parse(localStorage.getItem("ghl-keap"))
            const config = {
                locationId: session.ghl_token.locationId,
                contact: ghlContact,
                appointment: fullAppointment,
                selected_appointment: appointment
            }

            console.log(config);

            setUploadAppointmentLoading(true);
            create_convo_email(config)
            .then((result) => {
                result = result.data;
                console.log(result);
                if(result.success){
                    localStorage.setItem('ghl-keap', JSON.stringify({
                        ...session,
                        // ghl_token: result.ghl_token,
                        keap_mapped_appointments: session.keap_mapped_appointments ? [appointment, ...session.keap_mapped_appointments] : [appointment]
                    }))

                    setMappedAppointments(prev => {
                        if(prev && prev.length > 0){
                            return [appointment, ...prev]
                        } else {
                            return [appointment]
                        }
                    })

                    toast({
                        title: 'Appointment Mapped!',
                        description: "Keap Appointment Mapped successfully.",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            })
            .catch(e => {
                console.log(e.message);
                toast({
                    title: 'Failed!.',
                    description: e.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            })
            .finally(() =>{
                setUploadAppointmentLoading(false);
                onAppointmentClose();
                setAppointmentTab(0);
                setActiveStep(0);
                setSelectedAppointment(null);
            })

        } else {

        }
    }

    const changeAppointments = (endpoint) => {
        let session = JSON.parse(localStorage.getItem("ghl-keap"));

        console.log(endpoint);

        const config = {
            locationId: session.ghl_token.locationId,
            keap_code: searchParams.get("code"),
            uri: window.location.origin + window.location.pathname,
            // refresh_token: session.keap_token.refresh_token,
            endpoint: endpoint
        }

        setFetchAppointmentLoading(true);
        fetch_appointments(config)
        .then(async (result) => {
            result = result.data;
            console.log(result);
            if(result.success){;
                localStorage.setItem('ghl-keap', JSON.stringify({
                    ...session,
                    keap_token: result.keap_token
                }))
                let filteredAppointments = await result.keap_appointments.appointments.filter(appointment => {
                    const appointment_id = appointment.id;

                    return !mappedAppointments.some(mapped_appointment =>
                        mapped_appointment.id === appointment_id
                    );
                });
                result.keap_appointments.appointments = filteredAppointments;
                // setKeapContacts(result);
                setKeapAppointments(result.keap_apps)
            }
        })
        .catch(e => {
            console.log(e.message);
        })
        .finally(() =>{
            setFetchAppointmentLoading(false);
        })
    }

    const appointmentTabBack = () => {
        if(appointmentTab > 0){
            setAppointmentTab(prev => {
                setActiveStep(prev - 1)
                return prev - 1
            });
        }
    }

    const mapAllAppointments = async () => {

        let session = JSON.parse(localStorage.getItem("ghl-keap"))
        const config = {
            locationId: session.ghl_token.locationId,
            keap_code: searchParams.get("code"),
            uri: window.location.origin + window.location.pathname,
            endpoint: mapAllLink
        }

        let loading_payload = {
            locationId: session.ghl_token.locationId,
            loading: true
        }
        setMapAppointmentLoading(true);
        onAppointmentClose();
        setAppointmentTab(0);
        setActiveStep(0);
        setSelectedAppointment(null);

        try {
            await map_appointment_loading(loading_payload)
            let result = await get_all_appointments(config);
            if(result.data){
                result = result.data;
                console.log(result);
                toast({
                    title: 'Appointments Mapped!',
                    description: "Keap Appointments Mapped successfully.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                setMapAppointmentLoading(false);
                setMappedAppointments(result.keap_mapped_appointments)
                setMappedContacts(result.keap_mapped_contacts)
            } else {
                if (result.code === 'ECONNABORTED') {
                    console.log('Request timed out.');
                    toast({
                        title: 'In Progress',
                        description: 'Mapping is in progress. Kindly check after some time.',
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                else {
                    console.log('Request error:', result);
                    toast({
                        title: 'Failed',
                        description: 'Error in mapping contacts.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                    setMapAppointmentLoading(false);
                } 
            }
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out. Check your server or internet connection.');
                toast({
                    title: 'In Progress',
                    description: 'Mapping is in progress. Kindly check after some time',
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                });
            }
            // else {
            //     console.log('Error:', error);
            //     toast({
            //         title: 'Error',
            //         description: 'An error occurred while mapping contacts.',
            //         status: 'error',
            //         duration: 9000,
            //         isClosable: true,
            //     });
            //     setMapAppointmentLoading(false);
            // }
        }
    }

    return (
        <Modal closeOnOverlayClick={false} onClose={onAppointmentClose} size="4xl" isOpen={isAppointmentOpen}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Map Appointments</ModalHeader>
                <ModalBody mb="5" position="relative">

                    <Box>
                        <Box border="1px solid #e0e0e0" p="14px">
                            <ModalStepper
                                steps={steps}
                                activeStep={activeStep}
                            />
                        </Box>

                        <Box position="relative" height={fetchAppointmentLoading ? "5rem" : "auto"}>
                            
                            {fetchAppointmentLoading ? <Spinner size='xl' top="50%" left="50%" position="absolute" /> : <Box border="1px solid #e0e0e0" p="20px 10px 20px 10px" mt="5" display={appointmentTab === 0 ? "block" : "none"}>
                                <Text fontWeight="bold">Filters</Text>
                                <HStack>
                                    <Text>Get appointments from last: </Text>
                                    <Select id="filter-days" placeholder='Select option' w="50%">
                                        <option value='5' selected>5 days</option>
                                        <option value='10'>10 days</option>
                                        <option value='20'>20 days</option>
                                        <option value='30'>30 days</option>
                                        <option value='60'>60 days</option>
                                        <option value='120'>120 days</option>
                                        <option value='240'>240 days</option>
                                        <option value='300'>300 days</option>
                                        <option value='all'>All time</option>
                                    </Select>
                                </HStack>
                            </Box>}

                            {appointmentTab === 1 && <>
                                <Box position="relative" border="1px solid #e0e0e0" p="20px 10px 20px 10px" mt="5" display={appointmentTab === 1 ? "block" : "none"}>
                                    <HStack w="100%">
                                        <Button onClick={() => changeAppointments(keapAppointments.previous)}><ArrowBackIcon /></Button>
                                        <Spacer />
                                        <Button onClick={() => changeAppointments(keapAppointments.next)}><ArrowForwardIcon /></Button>
                                    </HStack>
                                    {checkAppointmentLoading ? <Spinner size='xl' top="50%" left="50%" position="absolute" /> : <TableContainer>
                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Title</Th>
                                                    <Th>Description</Th>
                                                    <Th>End date</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    keapAppointments && keapAppointments.appointments.map((appointment, idx) => {
                                                        return (
                                                            <Tr key={idx} onClick={() => appointmentTabNext(appointment)} cursor="pointer" _hover={{ bg: "#e8e8e8" }}>
                                                                <Td>{appointment.title}</Td>
                                                                <Td>{appointment.description}</Td>
                                                                <Td>{appointment.end_date}</Td>
                                                            </Tr>
                                                        )
                                                    })
                                                }
                                            </Tbody>
                                        </Table>
                                    </TableContainer>}
                                </Box>
                            </>}

                            {appointmentTab === 2 && <>
                                <Box border="1px solid #e0e0e0" p="20px 10px 20px 10px" mt="5" display={appointmentTab === 2 ? "block" : "none"}>
                                    <TableContainer>
                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>ID</Th>
                                                    <Th>Email</Th>
                                                    <Th>Is Available</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {selectedAppointment && selectedAppointment.map((field, idx) => {
                                                    return (
                                                        <Tr key={idx} onClick={() => appointmentTabNext(field)} cursor="pointer" _hover={{ bg: "#e8e8e8" }}>
                                                            <Td>{field.id}</Td>
                                                            <Td>{field.name}</Td>
                                                            <Td>{(field.isActive).toString()}</Td>
                                                        </Tr>
                                                    )
                                                })}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </>}

                        </Box>
                    </Box>

                </ModalBody>
                <Divider />
                <ModalFooter>
                    <HStack w="100%">
                        <Box>
                            <Button onClick={() => {
                                onAppointmentClose();
                                setAppointmentTab(0);
                                setActiveStep(0);
                            }} mr="3">Cancel</Button>
                            <Button mr="5" onClick={appointmentTabBack}>Back</Button>
                        </Box>
                        <Spacer />
                        <Button colorScheme='green' onClick={appointmentTabNext}>
                            {appointmentTab === 0 ? "Fetch Appointments" : appointmentTab === 2 ? uploadAppointmentLoading ? <Spinner /> : "Upload Appointment" : "Next"}
                        </Button>
                        {appointmentTab === 1 && <Button colorScheme='green' onClick={mapAllAppointments}>
                            {mappAllLoading ? <Spinner /> : "Map all Appointments"}
                        </Button>}
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
