import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button
} from '@chakra-ui/react'
import React from 'react'

export default function DetailModal({
    isDetailOpen,
    onDetailOpen,
    onDetailClose,
    selectedDetail,
    setSelectedDetail,
    keapTags
}) {
    return (
        <Modal closeOnOverlayClick={true} onClose={onDetailClose} size="3xl" isOpen={isDetailOpen}>
            <ModalOverlay />
            <ModalContent>
                {/* <ModalCloseButton /> */}
                <ModalBody position="relative">

                    {selectedDetail && selectedDetail.type === "task" ? <Box>
                        <TableContainer padding="10px">
                            <Table variant='simple' mt="1rem">
                                <Thead>
                                    <Tr bg="#fafafa">
                                        <Th>Keap Field</Th>
                                        <Th>Value</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {selectedDetail && selectedDetail.value.map((field, idx) => {
                                        return (
                                            <Tr key={idx}>
                                                <Td fontWeight="bold">{field[0]}</Td>
                                                <Td>{field[0] === "contact" ? 
                                                        field[1] !== null ? `(Email) ${field[1].email}` : "-"
                                                    : field[1] !== null ? 
                                                        typeof(field[1]) === "boolean" ? field[1].toString() : field[1]
                                                    : "-"}</Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box> : selectedDetail && selectedDetail.type === "appointment" ? <Box>
                        <TableContainer padding="10px">
                            <Table variant='simple' mt="1rem">
                                <Thead>
                                    <Tr bg="#fafafa">
                                        <Th>Keap Field</Th>
                                        <Th>Value</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {selectedDetail && selectedDetail.value.map((field, idx) => {
                                        return (
                                            <Tr key={idx}>
                                                <Td>{field[0]}</Td>
                                                <Td>{field[1] !== null ? 
                                                        typeof(field[1]) === "boolean" ? field[1].toString() : field[1]
                                                    : "-"}</Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box> : selectedDetail && selectedDetail.type === "contact" ? <Box>
                        <TableContainer padding="10px">
                            <Table variant='simple' mt="1rem">
                                <Thead>
                                    <Tr bg="#fafafa">
                                        <Th>Keap Field</Th>
                                        <Th>Value</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {selectedDetail && selectedDetail.value.map((field, idx) => {
                                        return (
                                            <Tr key={idx}>
                                                <Td>{field[0]}</Td>
                                                <Td>
                                                    {
                                                        field[0] === "email_addresses" ?
                                                            field[1].length > 0 ? field[1][0].email : "-"
                                                        : field[0] === "addresses" ?
                                                            field[1].length > 0 ? `${field[1][0].line1}, ${field[1][0].line2}, ${field[1][0].locality}, ${field[1][0].postal_code}, ${field[1][0].region}` : "-"
                                                        : field[0] === "phone_numbers" ?
                                                            field[1].length > 0 ? field[1][0].number : "-"
                                                        : field[0] === "tags" ? field[1].length > 0 ? field[1].join(", ") : "-"
                                                        : field[0] === "lastUpdatedBy" ?
                                                            field[1].length > 0 ? field[1][1].timestamp : "-"
                                                        : field[0] === "createdBy" ?
                                                            field[1].length > 0 ? field[1][1].timestamp : "-"
                                                        : typeof(field[1]) === "boolean" ? field[1].toString()
                                                        : field[1] === null ? "-" : field[1]
                                                    }
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box> : selectedDetail && selectedDetail.type === "email" ? <Box>
                        <TableContainer padding="10px">
                            <Table variant='simple' mt="1rem">
                                <Thead>
                                    <Tr bg="#fafafa">
                                        <Th>Keap Field</Th>
                                        <Th>Value</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {selectedDetail && selectedDetail.value.map((field, idx) => {
                                        return (
                                            <Tr key={idx}>
                                                <Td>{field[0]}</Td>
                                                <Td>{field[1] ? field[1] : "-"}</Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box> : <></>}

                </ModalBody>
                <ModalFooter>
                    <Button onClick={onDetailClose} mr="3">Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

// : selectedDetail.type === "contact" ? <Box>
// <TableContainer padding="10px">
//     <Table variant='simple' mt="1rem">
//         <Thead>
//             <Tr bg="#fafafa">
//                 <Th>Keap Field</Th>
//                 <Th>Value</Th>
//             </Tr>
//         </Thead>
//         <Tbody>
//             {selectedDetail && selectedDetail.value.map((field, idx) => {
//                 return (
//                     <Tr key={idx}>
//                         <Td>{field[0]}</Td>
//                         <Td>
//                             {
//                                 field[0] === "email_addresses" ?
//                                     field[1].length > 0 ? field[1][0].email : "-"
//                                 : field[0] === "addresses" ?
//                                     field[1].length > 0 ? `${field[1][0].line1}, ${field[1][0].line2}, ${field[1][0].locality}, ${field[1][0].postal_code}, ${field[1][0].region}` : "-"
//                                 : field[0] === "phone_numbers" ?
//                                     field[1].length > 0 ? field[1][0].number : "-"
//                                 : field[1] === null ? "-" : field[1]
//                             }
//                         </Td>
//                     </Tr>
//                 )
//             })}
//         </Tbody>
//     </Table>
// </TableContainer>
// </Box> : selectedDetail.type === "email" ? <Box>
// <TableContainer padding="10px">
//     <Table variant='simple' mt="1rem">
//         <Thead>
//             <Tr bg="#fafafa">
//                 <Th>Keap Field</Th>
//                 <Th>Value</Th>
//             </Tr>
//         </Thead>
//         <Tbody>
//             {selectedDetail && selectedDetail.value.map((field, idx) => {
//                 return (
//                     <Tr key={idx}>
//                         <Td>{field[0]}</Td>
//                         <Td>{field[1] ? field[1] : "-"}</Td>
//                     </Tr>
//                 )
//             })}
//         </Tbody>
//     </Table>
// </TableContainer>
// </Box>
