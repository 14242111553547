import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useQuery } from 'react-query'
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Heading,
    Text,
    Button,
    HStack,
    Box,
    Spacer,
    Link
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import axios from 'axios'

let current_percent;

let session = JSON.parse(localStorage.getItem("ghl-keap"));

function epoch (date) {
    return Math.floor(Date.parse(date)/1000)
  }

export default function MapCard({
    heading,
    cardType,
    count,
    onClickFunc,
    mapContactLoading,
    mapEmailLoading,
    mapAppointmentLoading,
    mapTaskLoading,
    href
}) {

    const queryFunction = async()=>{
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/keap/mapping_progress?&locationId=${session.ghl_location.id}`)
        return res.data
    }

    const {data} = useQuery('mapping-progress',queryFunction,{
        keepPreviousData: true
    })

    const contactData = data?.contact || {};
    const {
        createdAt: contactCreatedAt,
        estimated_finish_time: contactEstimatedFinishTime,
        updatedAt: contactUpdatedAt,
        status: contactStatus,
        to_map: contactToMap,
        mapped: contactMapped
    } = contactData;

    const emailData = data?.email || {};
    const {
        createdAt: emailCreatedAt,
        estimated_finish_time: emailEstimatedFinishTime,
        updatedAt: emailUpdatedAt,
        status: emailStatus,
        to_map: emailToMap,
        mapped: emailMapped
    } = emailData;

    const appointmentData = data?.appointment || {};
    const {
        createdAt: appointmentCreatedAt,
        estimated_finish_time: appointmentEstimatedFinishTime,
        updatedAt: appointmentUpdatedAt,
        status: appointmentStatus,
        to_map: appointmentToMap,
        mapped: appointmentMapped
    } = appointmentData;

    const taskData = data?.task || {};
    const {
        createdAt: taskCreatedAt,
        estimated_finish_time: taskEstimatedFinishTime,
        updatedAt: taskUpdatedAt,
        status: taskStatus,
        to_map: taskToMap,
        mapped: taskMapped
    } = taskData;

    const epoch_contact_createdAt = epoch(contactCreatedAt)
    const epoch_contact_estimated_finish_time = epoch(contactEstimatedFinishTime)    

    const epoch_email_createdAt = epoch(emailCreatedAt)
    const epoch_email_estimated_finish_time = epoch(emailEstimatedFinishTime)    

    const epoch_appointment_createdAt = epoch(appointmentCreatedAt)
    const epoch_appointment_estimated_finish_time = epoch(appointmentEstimatedFinishTime)  

    const epoch_task_createdAt = epoch(taskCreatedAt)
    const epoch_task_estimated_finish_time = epoch(taskEstimatedFinishTime)  

    const epoch_appointment_updatedAt = epoch(appointmentUpdatedAt)
    const epoch_email_updatedAt = epoch(emailUpdatedAt)
    const epoch_contact_updatedAt = epoch(contactUpdatedAt)
    const epoch_task_updatedAt = epoch(taskUpdatedAt)

    
    const handleLink = () => {
        console.log(href);
        window.open(href, '_blank');
    }

    const handleButtonClick = (cardType) => {
        console.log(href);
        window.open(`logData/${cardType}`, '_blank');
    }

    const currentDate = new Date()
    const currentTimeinMillis = currentDate.getTime()
    const currentTime = Math.floor(currentTimeinMillis/1000)

    const renderLoading = (type,{start, end, current,status, updatedAt, to_map, mapped})=>{

        if (data?.[type] && data?.[type]?.status === 'in_progress'){
            const percent = mapped/to_map;
            current_percent = (percent * 100).toFixed(2);
         
         const extra_seconds_wait = current - end
            if(!isNaN(updatedAt) && updatedAt <= current - 500){
                return(<p>Some Error has occured while mapping</p>)
            }
            else if(current >= start){
                if(status === 'in_progress'){
                    return(
                        <p>{current_percent}%</p>
                    )
                }
               else{
                 return(
                    <p>100%</p>
                )
               }
            }
        }
        else if (data?.[type]?.status === 'completed') {
            return <p>{`Previous ${type} mapping complete`}</p>;
        }
        else{
            return <p>{`Waiting for your first mapping`}</p>;
        }
    }
     
    useEffect(() => {
        renderLoading('contact', { start: epoch_contact_createdAt, end: epoch_contact_estimated_finish_time, current: currentTime, status: contactStatus, updatedAt: epoch_contact_updatedAt, to_map: contactToMap, mapped: contactMapped });
        renderLoading('email', { start: epoch_email_createdAt, end: epoch_email_estimated_finish_time, current: currentTime, status: emailStatus, updatedAt: epoch_email_updatedAt, to_map: emailToMap, mapped: emailMapped });
        renderLoading('appointment', { start: epoch_appointment_createdAt, end: epoch_appointment_estimated_finish_time, current: currentTime, status: appointmentStatus, updatedAt: epoch_appointment_updatedAt, to_map: appointmentToMap, mapped: appointmentMapped });
        renderLoading('task', { start: epoch_task_createdAt, end: epoch_task_estimated_finish_time, current: currentTime, status: taskStatus, updatedAt: epoch_task_updatedAt, to_map: taskToMap, mapped: taskMapped });
    }, [currentTime, data?.contact, data?.email, data?.appointment, data?.task]);
   


    return (
        <Card>
            <HStack>
                <Box>
                    <CardHeader>
                        <Heading size='md'>{heading}</Heading>
                    </CardHeader>
                    <CardBody>
                        <Text>Current count : <b>{count}</b></Text>
                    </CardBody>
                    <CardFooter>
                        <HStack>
                            <Button onClick={onClickFunc}  style={{ width: 210 }}>Map {heading}</Button>
                            <Link onClick={handleLink} fontSize="sm">See all mapped {cardType} <ExternalLinkIcon /></Link>
                            <Spacer />
                            <button onClick={()=>handleButtonClick(cardType)}>Logs</button>
                            <Spacer />
                        </HStack>
                    </CardFooter>
                </Box>
                <Spacer />
                <Box p="10">
                {cardType === 'contacts' && renderLoading('contact', { start: epoch_contact_createdAt, end: epoch_contact_estimated_finish_time, current: currentTime, status: contactStatus, updatedAt: epoch_contact_updatedAt, to_map: contactToMap, mapped: contactMapped  })}

                {cardType === 'emails' && renderLoading('email', { start: epoch_email_createdAt, end: epoch_email_estimated_finish_time, current: currentTime, status: emailStatus, updatedAt: epoch_email_updatedAt, to_map: emailToMap, mapped: emailMapped })}

                {cardType === 'appointments' && renderLoading('appointment', { start: epoch_appointment_createdAt, end: epoch_appointment_estimated_finish_time, current: currentTime, status: appointmentStatus, updatedAt: epoch_appointment_updatedAt, to_map: appointmentToMap, mapped: appointmentMapped })}

                {cardType === 'tasks' && renderLoading('task', { start: epoch_task_createdAt, end: epoch_task_estimated_finish_time, current: currentTime, status: taskStatus, updatedAt: epoch_task_updatedAt, to_map: taskToMap, mapped: taskMapped })}
                {
                    data?.[cardType.slice(0,cardType.length-1)] &&
                    <Text fontSize="sm" fontStyle="italic">{}</Text> 
                }
              
                </Box>
            </HStack>
        </Card>
    );      
}