import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from "axios";
import {
    Box,
    SimpleGrid,
    Text,
    Container,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Button,
    Textarea,
    HStack,
    Spinner,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Heading,
    useDisclosure,
    Spacer,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react'
import DetailModal from '../components/DetailModal';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

export default function Appointments() {
    let session = JSON.parse(localStorage.getItem("ghl-keap"));
    //statefull values
    const [selectedDetail, setSelectedDetail] = useState(null);
    const [appointments, setAppointments] = useState(null);
    const [error, setError] = useState(null);

    //hooks
    const { isOpen: isDetailOpen, onOpen: onDetailOpen, onClose: onDetailClose } = useDisclosure();
    const navigate = useNavigate();

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                let options = {
                    method: 'POST',
                    url: `${process.env.REACT_APP_BACKEND_URL}/api/ghl/paged_appointments?page=${currentPage}&limit=10`,
                    data: {
                        locationId: session.ghl_location.id
                    }
                };
            
                let res = await axios(options);
                res = res.data;
                setTotalPages(res.totalPages);
                setAppointments(res.data);
                setStart(res.startIndex)
                setLoading(false);
            } catch (error) {
                console.log(error);
                setError(error);
                setLoading(false);
            }
        }
        fetchData();
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const seeDetail = (detail) => {
        if(detail){
            console.log("to see detail", detail);
            var key_value_pair = Object.keys(detail.detail).map((key) => [key, detail.detail[key]]);
            console.log(key_value_pair);
            setSelectedDetail({type: detail.type, value: key_value_pair});
            onDetailOpen();
        }
    }

    return (
        <Box>
            <Container maxW='1200' padding="5" py="10">
            {/* <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>Go Back</Button> */}
                <Text fontWeight="bold" fontSize="xl" textAlign="center">Mapped Appointments</Text>
                <HStack justifyContent="center" mb="5">
                    <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        colorScheme='facebook'
                    >
                        <ArrowBackIcon />
                    </Button>
                    <Spacer />
                    <Text>{currentPage}/{totalPages}</Text>
                    <Spacer />
                    <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        colorScheme='facebook'
                    >
                        <ArrowForwardIcon />
                    </Button>
                </HStack>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead bg="#f2f2f2">
                            <Tr>
                                <Th>#</Th>
                                <Th>Title</Th>
                                <Th>Description</Th>
                                <Th>End Date</Th>
                            </Tr>
                        </Thead>
                        {!loading && !error && <Tbody>
                            {appointments && appointments?.map((appointment, index) => {
                                return (
                                    <Tr key={index} _hover={{ bg: "#fafafa", cursor: "pointer" }} onClick={() => seeDetail({ type: "appointment", detail: appointment })}>
                                        <Td>{Number(index + start + 1)}</Td>
                                        <Td>{appointment.title}</Td>
                                        <Td>{appointment.description}</Td>
                                        <Td>{new Date(appointment.end_date).toLocaleString()}</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>}
                    </Table>
                    {
                        !appointments && <Text textAlign="center" fontWeight="bold" mt="2">No mapped appointments</Text>
                    }
                </TableContainer>
                <center>
                    {loading && <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='green'
                        size='xl'
                        mt={5}
                    />}
                </center>
                {error && <Text>Error!</Text>}
            </Container>

            <DetailModal
                isDetailOpen={isDetailOpen}
                onDetailOpen={onDetailOpen}
                onDetailClose={onDetailClose}
                selectedDetail={selectedDetail}
                setSelectedDetail={setSelectedDetail}
            />
        </Box>
    )
}
