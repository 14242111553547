import React, { Fragment, useEffect, useState } from 'react'
import {
    Box,
    Button,
    Container,
    HStack,
    Heading,
    Link,
    Select,
    SimpleGrid,
    Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Input,
    Textarea,
    useToast,
} from '@chakra-ui/react';
import { get_orders } from '../apis/apiRequests';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";

export default function ViewOrder() {
    //hooks
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useToast()
    
    //state variables
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    //functions
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let url_params = {};
        for(var value of params.keys()) {
            url_params[value] = params.get(value);
        }
        console.log(url_params.email);

        const options = {
            method: 'GET',
            url: `${process.env.REACT_APP_BACKEND_URL}/api/ghl/orders?email=${url_params.email}`
        }

        setLoading(true);
        axios(options)
        .then(result => {
            if(result.data){
                result = result.data;
                console.log(result.data[0]);
                setData(result.data[0])
            }
        })
        .catch(error => {
            console.log(error);
            setError(error);
        })
        .finally(() => {
            setLoading(false);
        })

    }, [])

    const handleViewContact = (contact_id) => {
        if(contact_id === null || contact_id === "" || contact_id === undefined){
            toast({
                title: 'Error',
                description: "Unable to view contact.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    if (loading) return <Text>Loading...</Text>
    return (
        <Box>
            <Container maxW='1200' padding="5">
                <HStack gap="2" mb="10">
                    <Heading>Order Information</Heading>
                    <Link onClick={() => navigate(-1)}>(back to search results)</Link>
                </HStack>
                <Box>
                    <Box>
                        <Text fontWeight="bold">Contact Information</Text>
                        <Box ml="5" mt="3">
                            <Link target={data?.ghl_contact_id ? '_blank' : '_self'} onClick={() => handleViewContact(data?.ghl_contact_id)} href={data?.ghl_contact_id ? `https://app.gohighlevel.com/v2/location/${data?.location_id}/contacts/detail/${data?.ghl_contact_id}` : "#"} color="blue">{data?.contact?.first_name} {data?.contact?.last_name}</Link>
                            <Text>{data?.contact?.email}</Text>
                        </Box>
                    </Box>

                    <Box my="10">
                        <Text mb="5" fontWeight="bold">Orders</Text>
                        <Accordion defaultIndex={[0]} allowMultiple>
                            {
                                data && data?.orders?.map((order, idx) => {
                                    return <Fragment key={idx}>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton _expanded={{ bg: '#fafafa' }}>
                                                    <Box as="span" flex='1' textAlign='left' py="2">
                                                        <HStack gap="10">
                                                            <Text>Order #{idx + 1}</Text>
                                                            <Text mr="5">{order.title}</Text>
                                                        </HStack>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Box p="5">
                                                    <SimpleGrid gap={10} columns={[1, null, 2, null, 2]}>
                                                        <Box>
                                                            <Text fontWeight="bold" mb="2">Shipping Address</Text>
                                                            <Box fontSize="14px">
                                                                <HStack mb="3">
                                                                    <Text w="50%">First Name:</Text> <Input isDisabled={true} bg="#d4d4d4" value={order?.shipping_information?.first_name} />
                                                                </HStack>
                                                                <HStack mb="3">
                                                                    <Text w="50%">Middle Name:</Text> <Input isDisabled={true} bg="#d4d4d4" value={order?.shipping_information?.middel_name} _placeholder={{ color: "black" }} />
                                                                </HStack>
                                                                <HStack mb="3">
                                                                    <Text w="50%">Last Name:</Text> <Input isDisabled={true} bg="#d4d4d4" value={order?.shipping_information?.last_name} />
                                                                </HStack>
                                                                <HStack mb="3">
                                                                    <Text w="50%">Company Name:</Text> <Input isDisabled={true} bg="#d4d4d4" value={order?.shipping_information?.company} />
                                                                </HStack>
                                                                <HStack mb="3">
                                                                    <Text w="50%">Phone:</Text> <Input isDisabled={true} bg="#d4d4d4" value={order?.shipping_information?.phone} />
                                                                </HStack>
                                                                <HStack mb="3">
                                                                    <Text w="50%">Street 1:</Text> <Input isDisabled={true} bg="#d4d4d4" value={order?.shipping_information?.street1} />
                                                                </HStack>
                                                                <HStack mb="3">
                                                                    <Text w="50%">Street 2:</Text> <Input isDisabled={true} bg="#d4d4d4" value={order?.shipping_information?.street2} />
                                                                </HStack>
                                                                <HStack mb="3">
                                                                    <Text w="50%">City:</Text> <Input isDisabled={true} bg="#d4d4d4" value={order?.shipping_information?.city} />
                                                                </HStack>
                                                                <HStack mb="3">
                                                                    <Text w="50%">State:</Text> <Input isDisabled={true} bg="#d4d4d4" value={order?.shipping_information?.state} />
                                                                </HStack>
                                                                <HStack mb="3">
                                                                    <Text w="50%">Zip:</Text> <Input isDisabled={true} bg="#d4d4d4" value={order?.shipping_information?.zip} />
                                                                </HStack>
                                                                <HStack mb="3">
                                                                    <Text w="50%">Country:</Text> <Input isDisabled={true} bg="#d4d4d4" value={order?.shipping_information?.country} />
                                                                </HStack>
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            <Text fontWeight="bold" mb="2">Order Information</Text>
                                                            <Box fontSize="14px">
                                                                <HStack mb="3">
                                                                    <Text w="50%">Order Title:</Text> <Input isDisabled={true} bg="#d4d4d4" value={order?.title} />
                                                                </HStack>
                                                                <HStack mb="3">
                                                                    <Text w="50%">Order Date:</Text> <Input isDisabled={true} bg="#d4d4d4" value={(new Date(order?.order_date)).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })} _placeholder={{ color: "black" }} />
                                                                </HStack>
                                                                <HStack mb="3">
                                                                    <Text w="50%">Pay Status:</Text> <Text color={order?.status === "PAID" ? "green" : "red"}>{order?.status}</Text>
                                                                </HStack>
                                                                <HStack mb="3">
                                                                    <Text w="50%">Order Notes:</Text> <Textarea isDisabled={true} bg="#d4d4d4" value={order?.notes} />
                                                                </HStack>
                                                            </Box>
                                                        </Box>
                                                    </SimpleGrid>

                                                    <Box mt="5">
                                                        <Text fontWeight="bold" mb="2">Order Items</Text>
                                                        <TableContainer mb="10">
                                                            <Table variant='simple'>
                                                                <Thead bg="#f2f2f2">
                                                                    <Tr>
                                                                        <Th>Name</Th>
                                                                        <Th>Price/Unit</Th>
                                                                        <Th>Type</Th>
                                                                        <Th>Total Price ($)</Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {
                                                                        order && order?.order_items?.map((item, index) => {
                                                                            return (
                                                                                <Tr key={index}>
                                                                                    <Td>{item.name}</Td>
                                                                                    <Td>$ {(item.price).toLocaleString()} x 1</Td>
                                                                                    <Td>{item.type}</Td>
                                                                                    <Td>$ {(Number(item.price) * Number(item.quantity)).toLocaleString()}</Td>
                                                                                </Tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </Tbody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Box>
                                                </Box>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Fragment>
                                })
                            }
                        </Accordion>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
