import {
    Box,
    SimpleGrid,
    Text,
    Button,
    HStack,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Select, 
    Spacer,
    Divider,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    useSteps
} from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useSearchParams } from 'react-router-dom';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import ModalStepper from './ModalStepper';
import { check_keap_email, create_convo_email, create_email, fetch_emails, get_all_emails, map_email_loading } from '../apis/apiRequests';

const backend_url = process.env.REACT_APP_BACKEND_URL;

const steps = [
    { title: 'Filters', description: 'by days created since' },
    { title: 'Emails', description: 'Select an email' },
    { title: 'Map', description: 'Map to corresponding fields' },
]

function date_by_subtracting_days(date, days) {
    return new Date(
        date.getFullYear(), 
        date.getMonth(), 
        date.getDate() - days,
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
    );
}

export default function EmailModal({
    onEmailClose,
    isEmailOpen,
    emailTab,
    fetchEmailLoading,
    keapEmails,
    setKeapEmails,
    selectedEmail,
    setEmailTab,
    uploadEmailLoading,
    setSelectedEmail,
    mappedEmails,
    setMappedContacts,
    setMappedEmails,
    setFetchEmailLoading,
    setUploadEmailLoading,
    setMapEmailLoading,
    mapEmailLoading
}) {
    const [searchParams] = useSearchParams();
    const toast = useToast();

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    //loading states
    const [checkEmailLoading, setCheckEmailLoading] = useState(false);
    const [mappAllLoading, setMapAllLoading] = useState(false);

    //value states
    const [ghlContact, setGhlContact] = useState(null);
    const [fullEmail, setFullEmail] = useState(null);
    const [email, setEmail] = useState(null);
    const [mapAllLink, setMapAllLink] = useState("https://api.infusionsoft.com/crm/rest/v1/emails");

    //email modal tab
    const emailTabNext = (selected = null) => {
        // if(contactTab < 4) setContactTab(prev => prev + 1);

        if(emailTab === 0){
            let session = JSON.parse(localStorage.getItem("ghl-keap"))

            var select = document.getElementById('filter-days');
            var selected_value = select.options[select.selectedIndex].value;
            console.log("filter value", selected_value);

            let endpoint_hit;
            if(selected_value === "all"){
                endpoint_hit = "https://api.infusionsoft.com/crm/rest/v1/emails?limit=10";
                setMapAllLink("https://api.infusionsoft.com/crm/rest/v1/emails");
            } else {
                let since_date = date_by_subtracting_days(new Date(), Number(selected_value)).toISOString();
                endpoint_hit = `https://api.infusionsoft.com/crm/rest/v1/emails?since_sent_date=${since_date}&limit=10`;
                setMapAllLink(`https://api.infusionsoft.com/crm/rest/v1/emails?since_sent_date=${since_date}`);
            }

            console.log(endpoint_hit);

            const config = {
                locationId: session.ghl_token.locationId,
                keap_code: searchParams.get("code"),
                uri: window.location.origin + window.location.pathname,
                // refresh_token: session.keap_token.refresh_token,
                endpoint: endpoint_hit
            }

            setFetchEmailLoading(true);
            fetch_emails(config)
            .then((result) => {
                result = result.data;
                console.log(result);
                if(result.success){;
                    localStorage.setItem('ghl-keap', JSON.stringify({
                        ...session,
                        keap_token: result.keap_token
                    }))
                    // let filteredEmails = null;
                    // if(mappedEmails){
                    //     filteredEmails = result.keap_emails.emails.filter(email => {
                    //         const email_id = email.id;
    
                    //         return !mappedEmails.some(mapped_email =>
                    //             mapped_email.id === email_id
                    //         );
                    //     });
                    //     result.keap_emails.emails = filteredEmails;
                    // }
                    
                    setKeapEmails(result.keap_emails);
                    setEmailTab(prev => {
                        setActiveStep(prev + 1)
                        return prev + 1
                    })
                }
            })
            .catch(e => {
                console.log(e.message);
            })
            .finally(() =>{
                setFetchEmailLoading(false);
            })

        } else if (emailTab === 1){
            let session = JSON.parse(localStorage.getItem("ghl-keap"))
            console.log(selected);
            const payload = {
                "locationId": session.ghl_token.locationId,
                "email": selected
            }

            setCheckEmailLoading(true);
            check_keap_email(payload)
            .then((result) => {
                if(result.data){
                    result = result.data;
                    if(result.success){
                        console.log(result);
                        setGhlContact(result.ghl_contact);
                        setFullEmail(result.email);
                        setEmail(selected);
                        toast({
                            title: 'Success!',
                            description: "Email Verified.",
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                        })
    
                        var key_value_pair = Object.keys(selected).map((key) => [key, selected[key]]);
                        console.log(key_value_pair);
                        
                        setSelectedEmail(key_value_pair);
                        setEmailTab(prev => {
                            setActiveStep(prev + 1)
                            return prev + 1
                        });
                    }else {
                        toast({
                            title: 'Warning',
                            description: "Invalid Email",
                            status: 'warning',
                            duration: 9000,
                            isClosable: true,
                        })
                    }
                } else {
                    toast({
                        title: 'Warning',
                        description: "Invalid Email",
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            })
            .catch(e => {
                console.log(e.message);
                toast({
                    title: 'Failed!.',
                    description: e.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            })
            .finally(() =>{
                setCheckEmailLoading(false);
            })

        } else if (emailTab === 2){
            let session = JSON.parse(localStorage.getItem("ghl-keap"))
            const config = {
                locationId: session.ghl_token.locationId,
                contact: ghlContact,
                email: fullEmail,
                selected_email: email
            }

            console.log(config);

            setUploadEmailLoading(true);
            create_convo_email(config)
            .then((result) => {
                result = result.data;
                console.log(result);
                if(result.success){
                    localStorage.setItem('ghl-keap', JSON.stringify({
                        ...session,
                        // ghl_token: result.ghl_token,
                        keap_mapped_emails: session.keap_mapped_emails ? [email, ...session.keap_mapped_emails] : [email]
                    }))

                    setMappedEmails(prev => {
                        if(prev && prev.length > 0){
                            return [email, ...prev]
                        } else {
                            return [email]
                        }
                    })

                    toast({
                        title: 'Email Mapped!',
                        description: "Keap Email Mapped successfully.",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            })
            .catch(e => {
                console.log(e.message);
                toast({
                    title: 'Failed!.',
                    description: e.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            })
            .finally(() =>{
                setUploadEmailLoading(false);
                onEmailClose();
                setEmailTab(0);
                setActiveStep(0);
                setSelectedEmail(null);
            })

        } else {

        }
    }

    const changeEmails = (endpoint) => {
        let session = JSON.parse(localStorage.getItem("ghl-keap"));

        console.log(endpoint);

        const config = {
            locationId: session.ghl_token.locationId,
            keap_code: searchParams.get("code"),
            uri: window.location.origin + window.location.pathname,
            // refresh_token: session.keap_token.refresh_token,
            endpoint: endpoint
        }

        setFetchEmailLoading(true);
        fetch_emails(config)
        .then(async (result) => {
            result = result.data;
            console.log(result);
            if(result.success){;
                localStorage.setItem('ghl-keap', JSON.stringify({
                    ...session,
                    keap_token: result.keap_token
                }))
                let filteredEmails = await result.keap_emails.emails.filter(email => {
                    const email_id = email.id;

                    return !mappedEmails.some(mapped_email =>
                        mapped_email.id === email_id
                    );
                });
                result.keap_emails.emails = filteredEmails;
                // setKeapContacts(result);
                setKeapEmails(result.keap_emails)
            }
        })
        .catch(e => {
            console.log(e.message);
        })
        .finally(() =>{
            setFetchEmailLoading(false);
        })
    }

    const emailTabBack = () => {
        if(emailTab > 0){
            setEmailTab(prev => {
                setActiveStep(prev - 1)
                return prev - 1
            });
        }
    }

    const mapAllEmails = async () => {
        
        console.log(mapAllLink);

        let session = JSON.parse(localStorage.getItem("ghl-keap"))
        const config = {
            locationId: session.ghl_token.locationId,
            keap_code: searchParams.get("code"),
            uri: window.location.origin + window.location.pathname,
            endpoint: mapAllLink
        }

        let loading_payload = {
            locationId: session.ghl_token.locationId,
            loading: true
        }
        setMapEmailLoading(true);
        onEmailClose();
        setEmailTab(0);
        setActiveStep(0);
        setSelectedEmail(null);

        // map_email_loading(loading_payload)
        //     .then(result => {
        //         result = result.data;
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     })
        // get_all_emails(config)
        //     .then(result => {
                
                
        //     })
        //     .catch(error => {
        //         console.log(error.message)
        //     })

        try {
            await map_email_loading(loading_payload)
            let result = await get_all_emails(config);
            if(result.data){
                result = result.data;
                console.log(result);
                toast({
                    title: 'Emails Mapped!',
                    description: "Keap Emails Mapped successfully.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                setMapEmailLoading(false);
                setMappedEmails(result.keap_mapped_emails)
                setMappedContacts(result.keap_mapped_contacts)
            } else {
                if (result.code === 'ECONNABORTED') {
                    console.log('Request timed out.');
                    toast({
                        title: 'In Progress',
                        description: 'Mapping is in progress. Kindly check after some time.',
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    });
                } else {
                    console.log('Request error:', result);
                    toast({
                        title: 'Failed',
                        description: 'Error in mapping contacts.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                    setMapEmailLoading(false);
                }
            }
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out. Check your server or internet connection.');
                toast({
                    title: 'In Progress',
                    description: 'Mapping is in progress. Kindly check after some time',
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                console.log('Error:', error);
                toast({
                    title: 'Error',
                    description: 'An error occurred while mapping contacts.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
                setMapEmailLoading(false);
            }
        }
    }

    return (
        <Modal closeOnOverlayClick={false} onClose={onEmailClose} size="4xl" isOpen={isEmailOpen}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Map Emails</ModalHeader>
                <ModalBody mb="5" position="relative">

                    <Box>
                        <Box border="1px solid #e0e0e0" p="14px">
                            <ModalStepper
                                steps={steps}
                                activeStep={activeStep}
                            />
                        </Box>

                        <Box position="relative" height={fetchEmailLoading ? "5rem" : "auto"}>
                            
                            {fetchEmailLoading ? <Spinner size='xl' top="50%" left="50%" position="absolute" /> : <Box border="1px solid #e0e0e0" p="20px 10px 20px 10px" mt="5" display={emailTab === 0 ? "block" : "none"}>
                                <Text fontWeight="bold">Filters</Text>
                                <HStack>
                                    <Text>Get emails from last: </Text>
                                    <Select id="filter-days" placeholder='Select option' w="50%">
                                        <option value='5' selected>5 days</option>
                                        <option value='10'>10 days</option>
                                        <option value='20'>20 days</option>
                                        <option value='30'>30 days</option>
                                        <option value='60'>60 days</option>
                                        <option value='120'>120 days</option>
                                        <option value='240'>240 days</option>
                                        <option value='300'>300 days</option>
                                        <option value='all'>All time</option>
                                    </Select>
                                </HStack>
                            </Box>}

                            {emailTab === 1 && <>
                                <Box position="relative" border="1px solid #e0e0e0" p="20px 10px 20px 10px" mt="5" display={emailTab === 1 ? "block" : "none"}>
                                    <HStack w="100%">
                                        <Button onClick={() => changeEmails(keapEmails.previous)}><ArrowBackIcon /></Button>
                                        <Spacer />
                                        <Button onClick={() => changeEmails(keapEmails.next)}><ArrowForwardIcon /></Button>
                                    </HStack>
                                    {checkEmailLoading ? <Spinner size='xl' top="50%" left="50%" position="absolute" /> : <TableContainer>
                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Id</Th>
                                                    <Th>Subject</Th>
                                                    <Th>Sent To</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    keapEmails && keapEmails.emails.map((email, idx) => {
                                                        return (
                                                            <Tr key={idx} onClick={() => emailTabNext(email)} cursor="pointer" _hover={{ bg: "#e8e8e8" }}>
                                                                <Td>{email.id}</Td>
                                                                <Td>{email.subject}</Td>
                                                                <Td>{email.sent_to_address}</Td>
                                                            </Tr>
                                                        )
                                                    })
                                                }
                                            </Tbody>
                                        </Table>
                                    </TableContainer>}
                                </Box>
                            </>}

                            {emailTab === 2 && <>
                                <Box border="1px solid #e0e0e0" p="20px 10px 20px 10px" mt="5" display={emailTab === 2 ? "block" : "none"}>
                                    <TableContainer>
                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Keap Field</Th>
                                                    <Th>Value</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {selectedEmail && selectedEmail.map((field, idx) => {
                                                    return (
                                                        <Tr key={idx}>
                                                            <Td>{field[0]}</Td>
                                                            <Td>{field[1] ? field[1] : "-"}</Td>
                                                        </Tr>
                                                    )
                                                })}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </>}

                        </Box>
                    </Box>

                </ModalBody>
                <Divider />
                <ModalFooter>
                    <HStack w="100%">
                        <Box>
                            <Button onClick={() => {
                                onEmailClose();
                                setEmailTab(0);
                                setActiveStep(0);
                            }} mr="3">Cancel</Button>
                            <Button mr="5" onClick={emailTabBack}>Back</Button>
                        </Box>
                        <Spacer />
                        <Button colorScheme='green' onClick={emailTabNext}>
                            {emailTab === 0 ? "Fetch Emails" : emailTab === 2 ? uploadEmailLoading ? <Spinner /> : "Upload Email" : "Next"}
                        </Button>
                        {emailTab === 1 && <Button colorScheme='green' onClick={mapAllEmails}>
                            {mappAllLoading ? <Spinner /> : "Map all Emails"}
                        </Button>}
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
