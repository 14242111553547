import {
    Text,
    Spinner,
    Flex,
    Container,
    Heading,
    Stack,
    Button,
    Icon,
    IconProps,
    Box
} from '@chakra-ui/react'
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { authActions } from '../redux/store';

const backend_url = process.env.REACT_APP_BACKEND_URL;

export default function KeapConnect() {
    //react hooks
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    //loading states
    const [loading, setLoading] = useState(false);

    const connectKeap = () => {
        setLoading(true);
        var code = searchParams.get("code");

        let config = {
            code: code,
        };
        let options = {
            method: 'POST',
            url: `${backend_url}/api/ghl/ghl_info`,
            data: config
        };
    
        axios(options)
        .then((result) => {
            result = result.data;
            console.log(result);

            if(result.success){
                localStorage.setItem('ghl-keap', JSON.stringify({
                    ghl_token: result.ghl_token,
                    ghl_location: result.ghl_location,
                    ghl_creds: result.ghl_creds ?  result.ghl_creds : null,
                    keap_mapped_contacts: result.keap_mapped_contacts ? result.keap_mapped_contacts : null,
                    keap_mapped_emails: result.keap_mapped_emails ? result.keap_mapped_emails : null,
                    keap_mapped_tasks: result.keap_mapped_tasks ? result.keap_mapped_tasks : null,
                    keap_mapped_apps: result.keap_mapped_apps ? result.keap_mapped_apps : null
                }))

                var base_url = window.location.origin;
                var client_id = process.env.REACT_APP_KEAP_CLIENT_ID;
                var redirect_uri = `${base_url}/dashboard`;
                window.location.href = `https://accounts.infusionsoft.com/app/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=full`

                dispatch(authActions.keapLogin());
            }
        })
        .catch((error) => {
            console.log(error.message);
        })
        .finally(() => {
            // setLoading(false);
        })
    }
    return (
        <Box>
            <Container maxW={'5xl'}>
                <Stack
                    textAlign={'center'}
                    align={'center'}
                    spacing={{ base: 8, md: 10 }}
                    py={{ base: 20, md: 28 }}>
                    <Heading
                    fontWeight={600}
                    fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                    lineHeight={'110%'}
                >
                    Connect to{' '}
                    <Text as={'span'} color="green.400">
                        Keap Account
                    </Text>
                    </Heading>
                    <Text color={'gray.500'} maxW={'3xl'}>
                        Connecting to your Keap account empowers you to efficiently manage your customer relationships, automate marketing campaigns, organize sales processes, and gain valuable insights to drive business growth. Keap's comprehensive platform offers a range of tools and integrations to help you optimize your sales and marketing efforts while providing a seamless experience for your customers.
                    </Text>
                    <Stack spacing={6} direction={'row'}>
                        <Button
                            rounded={'full'}
                            px={6}
                            colorScheme={'green'}
                            bg={'green.400'}
                            _hover={{ bg: 'green.500' }}
                            onClick={connectKeap}
                        >
                            {loading ? <Spinner /> : "Sign in to Keap"}
                        </Button>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}
