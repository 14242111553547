import {
    Box,
    SimpleGrid,
    Text,
    Button,
    HStack,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Select, 
    Spacer,
    Divider,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    useSteps
} from '@chakra-ui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import ModalStepper from './ModalStepper'
import { create_ghl_task, fetch_ghl_calendars, fetch_ghl_contacts,get_all_tasks ,fetch_keap_tasks, map_task_loading } from '../apis/apiRequests'

const backend_url = process.env.REACT_APP_BACKEND_URL;

const steps = [
    { title: 'Filters', description: 'by days created since' },
    { title: 'Tasks', description: 'Select a task' },
    { title: 'Assign Task', description: 'Assign task to one contact' },
    { title: 'Map', description: 'Map to corresponding fields' },
]

function date_by_subtracting_days(date, days) {
    return new Date(
        date.getFullYear(), 
        date.getMonth(), 
        date.getDate() - days,
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
    );
}

export default function TasksModal({
    onTaskClose,
    isTaskOpen,
    taskTab,
    fetchTaskLoading,
    keapTasks,
    setKeapTasks,
    selectedTask,
    setTaskTab,
    uploadTaskLoading,
    setSelectedTask,
    mappedTasks,
    setMappedContacts,
    setMappedTasks,
    setFetchTaskLoading,
    setMapTaskLoading,
    setUploadTaskLoading
}) {
    //hooks
    const [searchParams] = useSearchParams();
    const toast = useToast();

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    //loading states
    const [fetchContactLoading, setFetchContactLoading] = useState(false);
    const [contacts, setContacts] = useState(null);
    const [selectedContact, setSelectedContact] = useState(false);

    const [mapAllLink, setMapAllLink] = useState("https://api.infusionsoft.com/crm/rest/v1/tasks");

    //fetch contacts
    let temp_sess = JSON.parse(localStorage.getItem("ghl-keap"))
    
    const fetch_contacts = (endpoint_hit = `https://services.leadconnectorhq.com/contacts/?locationId=${temp_sess.ghl_token.locationId}&limit=10`) => {
        if(endpoint_hit === null){
            return;
        }
        console.log("inside");
        let session = JSON.parse(localStorage.getItem("ghl-keap"))
    
        const config = {
            endpoint: endpoint_hit,
            // refresh_token: session.ghl_token.refresh_token,
            locationId: session.ghl_location.id
        }

        setFetchContactLoading(true);
        fetch_ghl_contacts(config)
        .then((result) => {
            result = result.data;
            console.log("ghl contacts", result);
            if(result.success){
                localStorage.setItem('ghl-keap', JSON.stringify({
                    ...session,
                    ghl_token: result.ghl_token
                }))
                setContacts(result.ghl_contacts)
            }
        })
        .catch(e => {
            console.log(e.message);
        })
        .finally(() =>{
            setFetchContactLoading(false);
        })
    }

    //email modal tab
    const taskTabNext = (selected = null) => {
        // if(contactTab < 4) setContactTab(prev => prev + 1);

        if(taskTab === 0){
            let session = JSON.parse(localStorage.getItem("ghl-keap"))

            var select = document.getElementById('filter-days');
            var selected_value = select.options[select.selectedIndex].value;
            console.log("filter value", selected_value);

            let endpoint_hit;
            if(selected_value === "all"){
                endpoint_hit = "https://api.infusionsoft.com/crm/rest/v1/tasks?limit=10"
                setMapAllLink("https://api.infusionsoft.com/crm/rest/v1/tasks");
            } else {
                let since_date = date_by_subtracting_days(new Date(), Number(selected_value)).toISOString();
                endpoint_hit = `https://api.infusionsoft.com/crm/rest/v1/tasks?since=${since_date}&limit=10`
                setMapAllLink(`https://api.infusionsoft.com/crm/rest/v1/tasks?since_sent_date=${since_date}`)
            }

            console.log(endpoint_hit);

            const config = {
                locationId: session.ghl_token.locationId,
                keap_code: searchParams.get("code"),
                uri: window.location.origin + window.location.pathname,
                // refresh_token: session.keap_token.refresh_token,
                endpoint: endpoint_hit
            }

            setFetchTaskLoading(true);
            fetch_keap_tasks(config)
            .then((result) => {
                result = result.data;
                console.log(result);
                if(result.success){
                    localStorage.setItem('ghl-keap', JSON.stringify({
                        ...session,
                        keap_token: result.keap_token
                    }))
                    setKeapTasks(result.keap_tasks)
                    setTaskTab(prev => {
                        setActiveStep(prev + 1)
                        return prev + 1
                    })
                }
            })
            .catch(e => {
                console.log(e.message);
            })
            .finally(() =>{
                setFetchTaskLoading(false);
            })

        } else if (taskTab === 1){
            console.log(selected);
            if(selected){
                console.log(selected);
                var key_value_pair = Object.keys(selected).map((key) => [key, selected[key]]);
                console.log(key_value_pair);
                
                setSelectedTask(key_value_pair);

                setTaskTab(prev => {
                    setActiveStep(prev + 1)
                    return prev + 1
                });

                fetch_contacts();
            }
        } else if (taskTab === 2){
            // console.log("assign tab");
            console.log(selected);
            setSelectedContact(selected);
            setTaskTab(prev => {
                setActiveStep(prev + 1)
                return prev + 1
            });
            

        } else if (taskTab === 3){
            let session = JSON.parse(localStorage.getItem("ghl-keap"))
            // setTaskTab(prev => prev + 1);
            let obj = {
                // "refresh_token": session.ghl_token.refresh_token,
                "contactId": selectedContact.id,
                "locationId": session.ghl_location.id
            };
            selectedTask.map((field, index) => {
                if(field[0] === "title"){
                    obj["title"] = field[1];
                } else if(field[0] === "due_date"){
                    obj["dueDate"] = ((new Date(field[1])).toISOString()).slice(0, 14) + "00:00Z"
                } else if(field[0] === "completed"){
                    obj["completed"] = field[1];
                } else if(field[0] === "description"){
                    obj["body"] = field[1];
                }
            })
            console.log(obj);

            const config = obj

            setUploadTaskLoading(true);
            create_ghl_task(config)
            .then((result) => {
                result = result.data;
                console.log(result);
                if(result.success){
                    if(session.keap_mapped_tasks){
                        localStorage.setItem('ghl-keap', JSON.stringify({
                            ...session,
                            ghl_token: result.ghl_token,
                            keap_mapped_tasks: [result.task, ...session.keap_mapped_tasks]
                        }))
                    } else {
                        localStorage.setItem('ghl-keap', JSON.stringify({
                            ...session,
                            ghl_token: result.ghl_token,
                            keap_mapped_tasks: [result.task]
                        }))
                    }

                    setMappedTasks(prev => {
                        if(Array.isArray(prev)){
                            return [result.task, ...prev]
                        } else {
                            return [result.task]
                        }
                    })

                    toast({
                        title: 'Task Mapped!',
                        description: "Keap Task Mapped successfully.",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            })
            .catch(e => {
                console.log(e.message);
                toast({
                    title: 'Failed!.',
                    description: e.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            })
            .finally(() =>{
                setUploadTaskLoading(false);
                onTaskClose();
                setTaskTab(0);
                setActiveStep(0);
                setSelectedTask(null);
            })

        } else {

        }
    }

    const changeTasks = (endpoint) => {
        if(endpoint){
            let session = JSON.parse(localStorage.getItem("ghl-keap"));

            console.log(endpoint);

            const config = {
                locationId: session.ghl_token.locationId,
                keap_code: searchParams.get("code"),
                uri: window.location.origin + window.location.pathname,
                // refresh_token: session.keap_token.refresh_token,
                endpoint: endpoint
            }

            setFetchTaskLoading(true);
            fetch_keap_tasks(config)
            .then((result) => {
                result = result.data;
                console.log(result);
                if(result.success){;
                    localStorage.setItem('ghl-keap', JSON.stringify({
                        ...session,
                        keap_token: result.keap_token
                    }))
                    setKeapTasks(result.keap_tasks)
                }
            })
            .catch(e => {
                console.log(e.message);
            })
            .finally(() =>{
                setFetchTaskLoading(false);
            })
        }
    }

    const taskTabBack = () => {
        if(taskTab > 0){
            setTaskTab(prev => {
                setActiveStep(prev - 1)
                return prev - 1
            });
        }
    }
    
    const mapAllTasks = async () => {

        let session = JSON.parse(localStorage.getItem("ghl-keap"))
        const config = {
            locationId: session.ghl_token.locationId,
            keap_code: searchParams.get("code"),
            uri: window.location.origin + window.location.pathname,
            endpoint: mapAllLink
        }

        let loading_payload = {
            locationId: session.ghl_token.locationId,
            loading: true
        }
        setMapTaskLoading(true);
        onTaskClose();
        setTaskTab(0);
        setActiveStep(0);
        setSelectedTask(null);

        try {
            await map_task_loading(loading_payload)
            let result = await get_all_tasks(config);
            if(result.data){
                result = result.data;
                console.log(result);
                toast({
                    title: 'Tasks Mapped!',
                    description: "Keap Tasks Mapped successfully.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                setMapTaskLoading(false);
                setMappedTasks(result.keap_mapped_tasks)
                setMappedContacts(result.keap_mapped_contacts)
            } else {
                if (result.code === 'ECONNABORTED') {
                    console.log('Request timed out.');
                    toast({
                        title: 'In Progress',
                        description: 'Mapping is in progress. Kindly check after some time.',
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                else {
                    console.log('Request error:', result);
                    toast({
                        title: 'Failed',
                        description: 'Error in mapping contacts.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                    setMapTaskLoading(false);
                } 
            }
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out. Check your server or internet connection.');
                toast({
                    title: 'In Progress',
                    description: 'Mapping is in progress. Kindly check after some time',
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                });
            }
        }
    }

    return (
        <Modal closeOnOverlayClick={false} onClose={onTaskClose} size="4xl" isOpen={isTaskOpen}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Map Tasks</ModalHeader>
                <ModalBody mb="5" position="relative">

                    <Box>
                        <ModalStepper
                            steps={steps}
                            activeStep={activeStep}
                        />

                        <Box position="relative" height={fetchTaskLoading ? "5rem" : "auto"}>
                            
                            {fetchTaskLoading ? <Spinner size='xl' top="50%" left="50%" position="absolute" /> : <Box border="1px solid #e0e0e0" p="20px 10px 20px 10px" mt="5" display={taskTab === 0 ? "block" : "none"}>
                                <Text fontWeight="bold">Filters</Text>
                                <HStack>
                                    <Text>Get tasks from last: </Text>
                                    <Select id="filter-days" placeholder='Select option' w="50%">
                                        <option value='5' selected>5 days</option>
                                        <option value='10'>10 days</option>
                                        <option value='20'>20 days</option>
                                        <option value='300'>300 days</option>
                                        <option value='all'>All time</option>
                                    </Select>
                                </HStack>
                            </Box>}

                            {taskTab === 1 && <>
                                <Box border="1px solid #e0e0e0" p="20px 10px 20px 10px" mt="5" display={taskTab === 1 ? "block" : "none"}>
                                    <HStack w="100%">
                                        <Button onClick={() => changeTasks(keapTasks.previous)}><ArrowBackIcon /></Button>
                                        <Spacer />
                                        <Button onClick={() => changeTasks(keapTasks.next)}><ArrowForwardIcon /></Button>
                                    </HStack>
                                    {!fetchTaskLoading && <TableContainer>
                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Task Title</Th>
                                                    <Th>Description</Th>
                                                    <Th>Due date</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    keapTasks && keapTasks.tasks.map((task, idx) => {
                                                        return (
                                                            <Tr key={idx} onClick={() => taskTabNext(task)} cursor="pointer" _hover={{ bg: "#e8e8e8" }}>
                                                                <Td>{task.title}</Td>
                                                                <Td>{task.description}</Td>
                                                                <Td>{task.due_date}</Td>
                                                            </Tr>
                                                        )
                                                    })
                                                }
                                            </Tbody>
                                        </Table>
                                    </TableContainer>}
                                </Box>
                            </>}

                            {taskTab === 2 && <>
                                <Box border="1px solid #e0e0e0" p="20px 10px 20px 10px" mt="5" display={taskTab === 2 ? "block" : "none"}>
                                    <HStack w="100%">
                                        <Button onClick={() => fetch_contacts(contacts.meta.prevPage ? contacts.meta.prevPageUrl : null)}><ArrowBackIcon /></Button>
                                        <Spacer />
                                        <Button onClick={() => fetch_contacts(contacts.meta.nextPage ? contacts.meta.nextPageUrl : null)}><ArrowForwardIcon /></Button>
                                    </HStack>
                                    {fetchContactLoading && <Spinner size='xl' top="50%" left="50%" position="absolute" />}
                                    {!fetchContactLoading && <TableContainer>
                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>ID</Th>
                                                    <Th>Email</Th>
                                                    <Th>Contact Name</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    contacts && contacts.contacts.map((contact, idx) => {
                                                        return (
                                                            <Tr key={idx} onClick={() => taskTabNext(contact)} cursor="pointer" _hover={{ bg: "#e8e8e8" }}>
                                                                <Td>{contact.id}</Td>
                                                                <Td>{contact.email}</Td>
                                                                <Td>{contact.contactName}</Td>
                                                            </Tr>
                                                        )
                                                    })
                                                }
                                            </Tbody>
                                        </Table>
                                    </TableContainer>}
                                </Box>
                            </>}

                            {taskTab === 3 && <>
                                <Box border="1px solid #e0e0e0" p="20px 10px 20px 10px" mt="5" display={taskTab === 3 ? "block" : "none"}>
                                    <TableContainer>
                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Keap Field</Th>
                                                    <Th>Value</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {selectedTask && selectedTask.map((field, idx) => {
                                                    return (
                                                        <Tr key={idx}>
                                                            <Td>{field[0]}</Td>
                                                            <Td>{field[0] === "contact" ? 
                                                                    field[1] !== null ? `(Email) ${field[1].email}` : "-"
                                                                : field[1] !== null ? 
                                                                    typeof(field[1]) === "boolean" ? field[1].toString() : field[1]
                                                                : "-"}</Td>
                                                        </Tr>
                                                    )
                                                })}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </>}

                        </Box>
                    </Box>

                </ModalBody>
                <Divider />
                <ModalFooter>
                    <HStack w="100%">
                        <Box>
                            <Button onClick={() => {
                                onTaskClose();
                                setTaskTab(0);
                                setActiveStep(0);
                            }} mr="3">Cancel</Button>
                            <Button mr="5" onClick={taskTabBack}>Back</Button>
                        </Box>
                        <Spacer />
                        <Button colorScheme='green' onClick={() => taskTabNext()}>
                            {taskTab === 0 ? "Fetch Tasks" : taskTab === 3 ? uploadTaskLoading ? <Spinner /> : "Upload Task" : "Next"}
                        </Button>
                        {taskTab === 1 && <Button colorScheme='green' onClick={mapAllTasks}>
                            {fetchContactLoading ? <Spinner /> : "Map all Tasks"}
                        </Button>}
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
