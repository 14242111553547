const { configureStore, createSlice } = require("@reduxjs/toolkit");

const initialAuth = {
    auth: JSON.parse(localStorage.getItem("ghl_keap_user_auth")) || false,
    ghlAuth: JSON.parse(localStorage.getItem("ghlAuth")) || false,
    keapAuth: JSON.parse(localStorage.getItem("keapAuth")) || false,
}

const authSlice = createSlice({
    name: "auth",
    initialState: initialAuth,
    reducers: {
        login(state, actions){
            state.auth = true;
            localStorage.setItem('ghl_keap_user_auth', JSON.stringify(true));
        },
        ghlLogin(state, actions){
            state.ghlAuth = true;
            localStorage.setItem('ghlAuth', JSON.stringify(true));
        },
        keapLogin(state, actions){
            state.ghlAuth = true;
            localStorage.setItem('keapAuth', JSON.stringify(true));
        },
        logout(state, actions){
            state.auth = false;
            localStorage.removeItem('ghl_keap_user_auth');
            localStorage.removeItem('ghlAuth');
            localStorage.removeItem('keapAuth');
            localStorage.removeItem('ghl-keap');
        }
    }
})

export const authActions = authSlice.actions;

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
    }
})

export default store;