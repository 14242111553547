import {
    Box,
    SimpleGrid,
    Text,
    Container,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Button,
    Textarea,
    HStack,
    Spinner,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Heading,
    useDisclosure,
    Spacer,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useSearchParams } from 'react-router-dom';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import TasksModal from '../components/TasksModal';
import AppointmentModal from '../components/AppointmentModal';
import ContactModal from '../components/ContactModal';
import EmailModal from '../components/EmailModal';
import MapCard from '../components/MapCard';
import DetailModal from '../components/DetailModal';
import { useDispatch } from 'react-redux';
import { authActions } from '../redux/store';
import { keap_auth, keap_submit, ghl_submit, fetch_keap_account } from '../apis/apiRequests';

const backend_url = process.env.REACT_APP_BACKEND_URL;

const today_date_object = new Date();
let today_date = today_date_object.toUTCString().slice(0, 16);

export default function Dashboard() {
    //variables
    let local_session = JSON.parse(localStorage.getItem("ghl-keap"));

    //hooks
    const dispatch = useDispatch();

    //extract query strings
    const params = new URLSearchParams(window.location.search);
    let url_params = {};
    for(var value of params.keys()) {
        url_params[value] = params.get(value);
    }
    console.log(url_params);

    // console.log(window.location.search);
    // console.log(window.location.origin);

    //predefined hooks
    const [searchParams] = useSearchParams();
    const { isOpen: isContactOpen, onOpen: onContactOpen, onClose: onContactClose } = useDisclosure();
    const { isOpen: isEmailOpen, onOpen: onEmailOpen, onClose: onEmailClose } = useDisclosure();
    const { isOpen: isTaskOpen, onOpen: onTaskOpen, onClose: onTaskClose } = useDisclosure();
    const { isOpen: isAppointmentOpen, onOpen: onAppointmentOpen, onClose: onAppointmentClose } = useDisclosure();
    const { isOpen: isDetailOpen, onOpen: onDetailOpen, onClose: onDetailClose } = useDisclosure();
    const toast = useToast();

    // loading states
    const [loading, setLoading] = useState(false);
    const [keapLoading, setKeapLoading] = useState(false);
    const [ghlLoading, setGhlLoading] = useState(false);
    const [accountLoading, setAccountLoading] = useState(false);
    const [fetchContactLoading, setFetchContactLoading] = useState(false);
    const [uploadContactLoading, setUploadContactLoading] = useState(false);
    const [fetchEmailLoading, setFetchEmailLoading] = useState(false);
    const [uploadEmailLoading, setUploadEmailLoading] = useState(false);
    const [fetchTaskLoading, setFetchTaskLoading] = useState(false);
    const [uploadTaskLoading, setUploadTaskLoading] = useState(false);
    const [fetchAppointmentLoading, setFetchAppointmentLoading] = useState(false);
    const [uploadAppointmentLoading, setUploadAppointmentLoading] = useState(false);

    const [mapContactLoading, setMapContactLoading] = useState(false);
    const [mapEmailLoading, setMapEmailLoading] = useState(false);
    const [mapAppointmentLoading, setMapAppointmentLoading] = useState(false);
    const [mapTaskLoading, setMapTaskLoading] = useState(false);

    // input states
    const [keapKey, setKeapKey] = useState(local_session.keap_creds ? local_session.keap_creds.keap_client_id : "");
    const [keapSec, setKeapSec] = useState(local_session.keap_creds ? local_session.keap_creds.keap_client_secret : "");
    const [ghlApi, setGhlApi] = useState(local_session.ghl_creds ? local_session.ghl_creds.v1api ? local_session.ghl_creds.v1api : "" : "");

    //data states
    const [keapContacts, setKeapContacts] = useState(null);
    const [keapEmails, setKeapEmails] = useState(null);
    const [keapTasks, setKeapTasks] = useState(null);
    const [keapAppointments, setKeapAppointments] = useState(null);
    const [keapTags, setKeapTags] = useState(null);

    // other states
    const [visible, setVisible] = useState(false);
    const [keapChange, setKeapChange] = useState(false);
    const [ghlChange, setGhlChange] = useState(false);
    const [contactTab, setContactTab] = useState(0);
    const [emailTab, setEmailTab] = useState(0);
    const [taskTab, setTaskTab] = useState(0);
    const [appointmentTab, setAppointmentTab] = useState(0);
    const [dashboard, setDashboard] = useState(null);

    const [selectedContact, setSelectedContact] = useState(null);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [selectedTask, setSelectedTask] = useState(null);
    const [selectedAppointment, setSelectedAppointment] = useState(null);

    const [selectedDetail, setSelectedDetail] = useState(null);

    //mapped states
    const [mappedContacts, setMappedContacts] = useState(local_session.keap_mapped_contacts ? local_session.keap_mapped_contacts : null);
    const [mappedEmails, setMappedEmails] = useState(local_session.keap_mapped_emails ? local_session.keap_mapped_emails : null);
    const [mappedTasks, setMappedTasks] = useState(local_session.keap_mapped_tasks ? local_session.keap_mapped_tasks : null);
    const [mappedAppointments, setMappedAppointments] = useState(local_session.keap_mapped_apps ? local_session.keap_mapped_apps : null);

    //useeffect functions
    useEffect(() => {
        const fetchDetails = () => {
            setLoading(true);
            var session = JSON.parse(localStorage.getItem("ghl-keap"));
    
            let config = {
                locationId: session.ghl_token.locationId,
                keap_code: searchParams.get("code"),
                uri: window.location.origin + window.location.pathname,
                // refresh_token: session.keap_token ? session.keap_token.refresh_token : null,
            };
            
            keap_auth(config)
            .then((result) => {
                result = result.data;
    
                if(result.success){
                    localStorage.setItem('ghl-keap', JSON.stringify({
                        ...session,
                        keap_token: result.keap_token,
                        keap_creds: result.keap_creds
                    }))
    
                    if(result.keap_creds && result.keap_creds.keap_client_id && result.keap_creds.keap_client_secret){
                        setKeapKey(result.keap_creds.keap_client_id);
                        setKeapSec(result.keap_creds.keap_client_secret);
                    }
                }
            })
            .catch(e => {
                console.log(e)
            })
            .finally(() => {
                setLoading(false);
            })
        }
    
        // fetchDetails(); // Call fetchDetails inside useEffect
    
    }, []);

    const ghlSubmit = (e) => {
        e.preventDefault();
        // v1api
        let session = JSON.parse(localStorage.getItem("ghl-keap"))

        const config = {
            v1api: ghlApi,
            locationId: session.ghl_token.locationId
        }

        setGhlLoading(true);
        ghl_submit(config)
        .then((result) => {
            result = result.data;
            console.log(result);

            toast({
                title: 'Details uploaded!.',
                description: "GHL Account Details uploaded successfully.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })

            localStorage.setItem('ghl-keap', JSON.stringify({
                ...session,
                ghl_creds: {
                    v1api: ghlApi
                }
            }))
        })
        .catch(e => {
            console.log(e.message);
            toast({
                title: 'Failed!.',
                description: e.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        })
        .finally(() => {
            setGhlLoading(false);
            setGhlChange(false);
        })
    }

    //other on-click functions

    const fetchKeapAccount = () => {
        let session = JSON.parse(localStorage.getItem("ghl-keap"))

        const config = {
            locationId: session.ghl_token.locationId,
            keap_code: searchParams.get("code"),
            uri: window.location.origin + window.location.pathname,
        }

        setAccountLoading(true);
        fetch_keap_account(config)
        .then((result) => {
            result = result.data;
            console.log(result);
            if(result){
                setMapContactLoading(result.mapContactLoading || false)
                setMapEmailLoading(result.mapEmailLoading || false)
                setMapAppointmentLoading(result.mapAppointmentLoading || false)
                setMapTaskLoading(result.mapTaskLoading || false)
    
                if(result.success){
                    localStorage.setItem('ghl-keap', JSON.stringify({
                        ...session,
                        keap_token: result.keap_token,
                        keap_account: result.keap_account,
                        keap_contacts: result.keap_contacts,
                        keap_emails: result.keap_emails,
                        keap_tasks: result.keap_tasks,
                        keap_apps: result.keap_apps,
                        keap_tags: result.keap_tags,
                        keap_mapped_contacts: result.keap_mapped_contacts,
                        keap_mapped_emails: result.keap_mapped_emails
                    }))
    
                    toast({
                        title: 'Success!',
                        description: "Account Details fetched successfully.",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setKeapTags(result.keap_tags)
                    setMappedContacts(result.keap_mapped_contacts)
                    setMappedEmails(result.keap_mapped_emails)
                    setMappedTasks(result.keap_mapped_tasks)
                    setDashboard(JSON.parse(localStorage.getItem("ghl-keap")));
                }
            } else {
                toast({
                    title: 'Failed!',
                    description: "Server error, please try again.",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
            
        })
        .catch(e => {
            console.log(e.message);
            toast({
                title: 'Failed!',
                description: e.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        })
        .finally(() => {
            setAccountLoading(false);
        })
    }

    const logout = () => {
        dispatch(authActions.logout());
        let origin = window.location.origin;
        window.location.href = origin;
    }

    const seeDetail = (detail) => {
        if(detail){
            console.log("to see detail", detail);

            var key_value_pair = Object.keys(detail.detail).map((key) => [key, detail.detail[key]]);
            console.log(key_value_pair);

            setSelectedDetail({type: detail.type, value: key_value_pair});

            onDetailOpen();
        }
    }

    if(loading) {
        return <Spinner size='xl' top="50%" left="50%" position="fixed" />
    }

    return (
        <Box>
            <Container maxW='1200' padding="5">
                <HStack>
                    <Box>
                        <Text fontWeight="bold" fontSize="4xl">Keap Mapper</Text>
                        <Text color="gray" fontWeight="bold">{today_date}</Text>
                    </Box>
                    <Spacer />
                    <Button colorScheme="red" onClick={logout}>Logout</Button>
                </HStack>

                <Button colorScheme='facebook' mt="10" mb="5" onClick={fetchKeapAccount}>
                    { accountLoading ? <Spinner /> : "Fetch Keap Account" }
                </Button>

                {dashboard && !accountLoading && <>
                    <Box my="10">
                        <SimpleGrid columns={[1, null, 3, null, 3]} gap={6} mt='5'>
                            <Box width='100%' bg='#7A9FFF' position='relative' borderRadius='12px' color='#FFFFFF' boxShadow='0px 20px 60px rgba(101, 202, 255, 0.2)' p='20px' m='auto' h='100%'>
                                <HStack>
                                    <Box bg='rgba(12, 12, 12, 0.2)' borderRadius='6px' color='white' p='10px' display='inline-block'>
                                        <HStack gap='1'>
                                            <Text>Location ID</Text>
                                        </HStack>
                                    </Box>
                                </HStack>
                                <Box mt='3rem'>
                                    <Text fontWeight='700'>{dashboard && dashboard.ghl_location.id}</Text>
                                </Box>
                            </Box>

                            <Box width='100%' bg='#7AD1FF' position='relative' borderRadius='12px' color='#FFFFFF' boxShadow='0px 20px 60px rgba(2, 140, 213, 0.15)' p='20px' m='auto' h='100%'>
                                <Box bg='rgba(12, 12, 12, 0.2)' borderRadius='6px' color='white' p='10px' display='inline-block'>
                                    <HStack gap='1'>
                                        <Text>GHL Business Name</Text>
                                    </HStack>
                                </Box>
                                <Box mt='3rem'>
                                    <Text fontWeight='700'>{dashboard && dashboard.ghl_location.name}</Text>
                                </Box>
                            </Box>

                            <Box width='100%' bg='#de0d68' position='relative' borderRadius='12px' color='#FFFFFF' boxShadow='0px 20px 60px #ffbaba' p='20px' m='auto' h='100%'>
                                <Box bg='rgba(12, 12, 12, 0.2)' borderRadius='6px' color='white' p='10px' display='inline-block'>
                                    <HStack gap='1'>
                                        <Text>Keap Account Name</Text>
                                    </HStack>
                                </Box>
                                <Box mt='3rem'>
                                    <Text fontWeight='700'>{dashboard && dashboard.keap_account.name}</Text>
                                </Box>
                            </Box>

                            <Box width='100%' bg='#13d142' position='relative' borderRadius='12px' color='#FFFFFF' boxShadow='0px 20px 60px #ccffd9' p='20px' m='auto' h='100%'>
                                <Box bg='rgba(12, 12, 12, 0.2)' borderRadius='6px' color='white' p='10px' display='inline-block'>
                                    <HStack gap='1'>
                                        <Text>Keap Email</Text>
                                    </HStack>
                                </Box>
                                <Box mt='3rem'>
                                    <Text fontWeight='700'>{dashboard && dashboard.keap_account.email}</Text>
                                </Box>
                            </Box>
                        </SimpleGrid>
                    </Box>

                    <Box my="10">
                        <SimpleGrid spacing={8} columns={[1, null, 2, null, 2]}>
                            <MapCard
                                heading="Contacts"
                                cardType="contacts"
                                count={dashboard && dashboard.keap_contacts.count ? dashboard.keap_contacts.count : 0}
                                onClickFunc={onContactOpen}
                                mapContactLoading={mapContactLoading || false}
                                href={window.location.origin + "/contacts" + window.location.search }
                            />
                            <MapCard
                                heading="Emails"
                                cardType="emails"
                                count={dashboard && dashboard.keap_emails.count ? dashboard.keap_emails.count : 0}
                                onClickFunc={onEmailOpen}
                                mapEmailLoading={mapEmailLoading || false}
                                href={window.location.origin + "/emails" + window.location.search }
                            />
                            <MapCard
                                heading="Tasks"
                                cardType="tasks"
                                count={dashboard && dashboard.keap_tasks.count ? dashboard.keap_tasks.count : 0}
                                onClickFunc={onTaskOpen}
                                mapTaskLoading={mapTaskLoading || false}
                                href={window.location.origin + "/tasks" + window.location.search }
                            />
                            <MapCard
                                heading="Appointments"
                                cardType="appointments"
                                count={dashboard && dashboard.keap_apps.count ? dashboard.keap_apps.count : 0}
                                onClickFunc={onAppointmentOpen}
                                mapAppointmentLoading={mapAppointmentLoading || false}
                                href={window.location.origin + "/appointments" + window.location.search }
                            /> 
                        </SimpleGrid>

                        {/* <Box my="10">
                            <Text fontWeight="bold" fontSize="large" mb="5">Mapped Tasks</Text>
                            <TableContainer>
                                <Table variant='simple'>
                                    <Thead bg="#f2f2f2">
                                        <Tr>
                                            <Th>ID</Th>
                                            <Th>Title</Th>
                                            <Th>Body</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {mappedTasks && mappedTasks.map((task, index) => {
                                            return (
                                                <Tr key={index} _hover={{ bg: "#fafafa", cursor: "pointer" }} onClick={() => seeDetail({ type: "task", detail: task.task })}>
                                                    <Td>{task.task.id}</Td>
                                                    <Td>{task.task.title}</Td>
                                                    <Td>{task.task.body}</Td>
                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                                {
                                    !mappedTasks && <Text textAlign="center" fontWeight="bold" mt="2">No mapped tasks</Text>
                                }
                            </TableContainer>
                        </Box>

                        <Box my="10">
                            <Text fontWeight="bold" fontSize="large" mb="5">Mapped Appointments</Text>
                            <TableContainer>
                                <Table variant='simple'>
                                    <Thead bg="#f2f2f2">
                                        <Tr>
                                            <Th>ID</Th>
                                            <Th>Title</Th>
                                            <Th>Appointment Status</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {mappedApps && mappedApps.map((app, index) => {
                                            return (
                                                <Tr key={index} _hover={{ bg: "#fafafa", cursor: "pointer" }} onClick={() => seeDetail({ type: "appointment", detail: app })}>
                                                    <Td>{app.id}</Td>
                                                    <Td>{app.title}</Td>
                                                    <Td>{app.appoinmentStatus}</Td>
                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                                {
                                    !mappedApps && <Text textAlign="center" fontWeight="bold" mt="2">No mapped appointments</Text>
                                }
                            </TableContainer>
                        </Box> */}
                    </Box>
                </>}
            </Container>

            <ContactModal
                onContactClose={onContactClose}
                isContactOpen={isContactOpen}
                contactTab={contactTab}
                fetchContactLoading={fetchContactLoading}
                keapContacts={keapContacts}
                keapTags={keapTags}
                setKeapContacts={setKeapContacts}
                selectedContact={selectedContact}
                setContactTab={setContactTab}
                uploadContactLoading={uploadContactLoading}
                setSelectedContact={setSelectedContact}
                mappedContacts={mappedContacts}
                setMappedContacts={setMappedContacts}
                setFetchContactLoading={setFetchContactLoading}
                setUploadContactLoading={setUploadContactLoading}
                setMapContactLoading={setMapContactLoading}
                mapContactLoading={mapContactLoading || false}
            />

            <EmailModal
                onEmailClose={onEmailClose}
                isEmailOpen={isEmailOpen}
                emailTab={emailTab}
                fetchEmailLoading={fetchEmailLoading}
                keapEmails={keapEmails}
                setKeapEmails={setKeapEmails}
                selectedEmail={selectedEmail}
                setEmailTab={setEmailTab}
                uploadEmailLoading={uploadEmailLoading}
                setSelectedEmail={setSelectedEmail}
                mappedEmails={mappedEmails}
                setMappedContacts={setMappedContacts}
                setMappedEmails={setMappedEmails}
                setFetchEmailLoading={setFetchEmailLoading}
                setUploadEmailLoading={setUploadEmailLoading}
                setMapEmailLoading={setMapEmailLoading}
                mapEmailLoading={mapEmailLoading || false}
            />

            <TasksModal
                onTaskClose={onTaskClose}
                onTaskOpen={onTaskOpen}
                isTaskOpen={isTaskOpen}
                taskTab={taskTab}
                fetchTaskLoading={fetchTaskLoading}
                keapTasks={keapTasks}
                setKeapTasks={setKeapTasks}
                selectedTask={selectedTask}
                setTaskTab={setTaskTab}
                uploadTaskLoading={uploadTaskLoading}
                setSelectedTask={setSelectedTask}
                mappedTasks={mappedTasks}
                setMappedTasks={setMappedTasks}
                setMapTaskLoading={setMapTaskLoading}
                setFetchTaskLoading={setFetchTaskLoading}
                setUploadTaskLoading={setUploadTaskLoading || false}
            />

            <AppointmentModal
                isAppointmentOpen={isAppointmentOpen}
                onAppointmentOpen={onAppointmentOpen}
                onAppointmentClose={onAppointmentClose}
                appointmentTab={appointmentTab}
                fetchAppointmentLoading={fetchAppointmentLoading}
                keapAppointments={keapAppointments}
                setKeapAppointments={setKeapAppointments}
                selectedAppointment={selectedAppointment}
                setAppointmentTab={setAppointmentTab}
                uploadAppointmentLoading={uploadAppointmentLoading}
                setSelectedAppointment={setSelectedAppointment}
                mappedAppointments={mappedAppointments}
                setMappedAppointments={setMappedAppointments}
                setMapAppointmentLoading={setMapAppointmentLoading}
                setFetchAppointmentLoading={setFetchAppointmentLoading}
                setUploadAppointmentLoading={setUploadAppointmentLoading || false}
                
            />

            <DetailModal
                isDetailOpen={isDetailOpen}
                onDetailOpen={onDetailOpen}
                onDetailClose={onDetailClose}
                selectedDetail={selectedDetail}
                setSelectedDetail={setSelectedDetail}
                keapTags={keapTags}
            />
        </Box>
    )
}
