import React from 'react';
import {
  Box,
  Text,
} from '@chakra-ui/react';
import GhlLogin from './Pages/GhlLogin';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import KeapConnect from './Pages/KeapConnect';
import Dashboard from './Pages/Dashboard';
import { useSelector } from 'react-redux';
import NotFound from './Pages/NotFound';
import Contacts from './Pages/Contacts';
import Appointments from './Pages/Appointments';
import Emails from './Pages/Emails';
import Tasks from './Pages/Tasks';
import LogData from './Pages/Log';
import { QueryClient, QueryClientProvider } from "react-query";
import Orders from './Pages/Orders';
import ViewOrder from './Pages/ViewOrder';
import Frame from './Pages/Frame';

function App() {

  const queryClient = new QueryClient();
  const ghlAuth = useSelector(state => state.auth.ghlAuth);
  const keapAuth = useSelector(state => state.auth.keapAuth);
  
  return (
    <>
     <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/connect/ghl" />} />
          <Route path='/connect/ghl' element={<GhlLogin />} />
          <Route path='/connect/keap' element={ghlAuth ? <KeapConnect /> : <Navigate to="/connect/ghl" />} />
          <Route path='/dashboard' element={(ghlAuth && keapAuth) ? <Dashboard /> : <Navigate to="/connect/ghl" />} />
          <Route path='/contacts' element={(ghlAuth && keapAuth) ? <Contacts /> : <Navigate to="/connect/ghl" />} />
          <Route path='/appointments' element={(ghlAuth && keapAuth) ? <Appointments /> : <Navigate to="/connect/ghl" />} />
          <Route path='/tasks' element={(ghlAuth && keapAuth) ? <Tasks /> : <Navigate to="/connect/ghl" />} />
          <Route path='/emails' element={(ghlAuth && keapAuth) ? <Emails /> : <Navigate to="/connect/ghl" />} />
          <Route path='/logData/contacts' element={(ghlAuth && keapAuth) ? <LogData /> : <Navigate to="/connect/ghl" />} />
          <Route path='/logData/appointments' element={(ghlAuth && keapAuth) ? <LogData /> : <Navigate to="/connect/ghl" />} />
          <Route path='/logData/tasks' element={(ghlAuth && keapAuth) ? <LogData /> : <Navigate to="/connect/ghl" />} />
          <Route path='/logData/emails' element={(ghlAuth && keapAuth) ? <LogData /> : <Navigate to="/connect/ghl" />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/orders/view' element={<ViewOrder />} />
          {/* <Route path='/v2/location/:locatioinId/custom-menu-link/:linkid' element={<Frame />} /> */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
      </QueryClientProvider>
    </>
  );
}

export default App;