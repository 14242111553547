import {
Button,
Container,
HStack,
Heading,
Spacer,
Table,
TableContainer,
Tbody,
Td,
Text,
Th,
Thead,
Tr,
} from '@chakra-ui/react';
import axios from 'axios';
import { useQuery } from 'react-query';
import React, { useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

const getLogDataRQ = async (pageNumber,cardType) => {
  const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/keap/logs?mapping_type=${cardType.slice(0, -1)}&page=${pageNumber}&locationId=${session.ghl_location.id}`);
  return res.data;
};

let session = JSON.parse(localStorage.getItem("ghl-keap"));

const LogData = () => {

const urlSplitted= ((window.location.href).split("?")[0]).split("/")
const urlSplittedLength = (((window.location.href).split("?")[0]).split("/")).length

const cardType = urlSplitted[urlSplittedLength-1]

const [pageNumber, setPageNumber] = useState(1);

const { data: logs, isLoading } = useQuery(['log', pageNumber, cardType],()=> getLogDataRQ(pageNumber,cardType), {
  keepPreviousData: true,
});

const total_pages = 1

if (isLoading) return <div>loading...</div>;
return (
  <>
  
   <Container maxW='1200' padding="5" py="10">
   <Heading > {cardType} </Heading>          
    <TableContainer>
      <Table variant="simple">
        <Thead bg="#f2f2f2">
          <Tr>
            <Th>To Map</Th>
            <Th>Mapped</Th>
            <Th>Status from</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
          </Tr>
        </Thead>
        {!isLoading && (
          <Tbody>
            {logs &&
              logs.data.map((log, index) => {
                return (
                  <Tr
                    key={index}
                    _hover={{ bg: '#fafafa', cursor: 'pointer' }}
                  >
                    <Td>{log.to_map}</Td>
                    <Td>{log.mapped}</Td>
                    <Td>{log.status}</Td>
                    <Td>{new Date(log.createdAt).toLocaleString()}</Td>
                    <Td>{new Date(log.updatedAt).toLocaleString()}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        )}
      </Table>
      {!logs && (
        <Text textAlign="center" fontWeight="bold" mt="2">
          No mapped emails
        </Text>
      )}
    </TableContainer>
    <HStack justifyContent="center" mb="5">
                  <Button
                      onClick={() => setPageNumber((currentPage)=> currentPage - 1)}
                      isDisabled={pageNumber === 1}
                      colorScheme='facebook'
                  >
                      <ArrowBackIcon />
                  </Button>
                  <Spacer />
                  <Text>{pageNumber}/{total_pages}</Text>
                  <Spacer />
                  <Button
                     onClick={() => setPageNumber((currentPage)=> currentPage + 1)}
                      isDisabled={pageNumber === total_pages}
                      colorScheme='facebook'
                  >
                      <ArrowForwardIcon />
                  </Button>
              </HStack>
    </Container>
  </>
);
};

export default LogData;