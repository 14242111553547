import {
    Box,
    SimpleGrid,
    Text,
    Input,
    Button,
    HStack,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Select, 
    Spacer,
    Divider,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    useSteps
} from '@chakra-ui/react'
import axios from 'axios';
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import ModalStepper from './ModalStepper';
import { create_contact, fetch_contacts, get_all_contacts, map_all_contacts, map_contact_loading, map_ghl_keap_contacts } from '../apis/apiRequests';

const backend_url = process.env.REACT_APP_BACKEND_URL;

const keap_ghl_fields = [
    "email_addresses",
    "tag_ids",
    "addresses",
    "given_name",
    "phone_numbers",
    "company_name",
    "family_name"
]

const keap_banned_fields = [
    "ScoreValue",
    // "addresses",
    "anniversary",
    "birthday",
    "company",
    // "company_name",
    // "contact_type",
    "custom_fields",
    "date_created",
    // "email_addresses",
    "email_opted_in",
    "email_status",
    // "family_name",
    "fax_numbers",
    // "given_name",
    "id",
    "job_title",
    "last_updated",
    "lead_source_id",
    "middle_name",
    "owner_id",
    // "phone_numbers",
    "preferred_locale",
    // "preferred_name",
    "prefix",
    "social_accounts",
    "source_type",
    "spouse_name",
    "suffix",
    "time_zone",
    "last_updated_utc_millis"
    // "website"
]

const steps = [
    { title: 'Filters', description: 'by days created since' },
    { title: 'Contacts', description: 'Select a contact' },
    { title: 'Map', description: 'Map to corresponding fields' },
]

function date_by_subtracting_days(date, days) {
    return new Date(
        date.getFullYear(), 
        date.getMonth(), 
        date.getDate() - days,
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
    );
}

export default function ContactModal({
    onContactClose,
    isContactOpen,
    contactTab,
    fetchContactLoading,
    keapContacts,
    keapTags,
    setKeapContacts,
    selectedContact,
    setContactTab,
    uploadContactLoading,
    setSelectedContact,
    mappedContacts,
    setMappedContacts,
    setFetchContactLoading,
    setUploadContactLoading,
    setMapContactLoading,
    mapContactLoading
}) {
    const [searchParams] = useSearchParams();
    const toast = useToast();

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    //stateful values
    const [mapAllLink, setMapAllLInk] = useState("https://api.infusionsoft.com/crm/rest/v1/contacts");

    //loading states
    const [mappAllLoading, setMapAllLoading] = useState(false);

    const contactTabNext = (selected = null) => {
        // if(contactTab < 4) setContactTab(prev => prev + 1);

        if(contactTab === 0){
            let session = JSON.parse(localStorage.getItem("ghl-keap"))

            var select = document.getElementById('filter-days');
            var selected_value = select.options[select.selectedIndex].value;
            console.log("filter value", selected_value);

            let endpoint_hit;
            if(selected_value === "all"){
                endpoint_hit = "https://api.infusionsoft.com/crm/rest/v1/contacts?limit=10";
                setMapAllLInk("https://api.infusionsoft.com/crm/rest/v1/contacts");
            } else {
                let since_date = date_by_subtracting_days(new Date(), Number(selected_value)).toISOString();
                endpoint_hit = `https://api.infusionsoft.com/crm/rest/v1/contacts?since=${since_date}&limit=10`;
                setMapAllLInk(`https://api.infusionsoft.com/crm/rest/v1/contacts?since=${since_date}`);
            }

            console.log(endpoint_hit);

            const config = {
                locationId: session.ghl_token.locationId,
                keap_code: searchParams.get("code"),
                uri: window.location.origin + window.location.pathname,
                // refresh_token: session.keap_token.refresh_token,
                endpoint: endpoint_hit
            }

            setFetchContactLoading(true);
            fetch_contacts(config)
            .then((result) => {
                result = result.data;
                console.log(result);
                // console.log("Mapped contacts", mappedContacts);
                if(result.success){;
                    localStorage.setItem('ghl-keap', JSON.stringify({
                        ...session,
                        keap_token: result.keap_token
                    }))

                    // let filteredContacts = null;
                    // if(mappedContacts){
                    //     filteredContacts = result.keap_contacts.contacts.filter(contact => {
                    //         if(contact.email_addresses[0].email){
                    //             const email = contact.email_addresses[0].email;
    
                    //             return !mappedContacts.some(mapped_contact =>
                    //                 mapped_contact.contact.email === email
                    //             );
                    //         } else {
                    //             return false;
                    //         }
                    //     });
                    //     result.keap_contacts.contacts = filteredContacts;
                    // }
                    
                    setKeapContacts(result);
                    
                    setContactTab(prev => {
                        setActiveStep(prev + 1)
                        return prev + 1
                    })   
                }
            })
            .catch(e => {
                console.log(e.message);
            })
            .finally(() =>{
                setFetchContactLoading(false);
            })

        } else if (contactTab === 1){
            console.log(selected);
            var key_value_pair = Object.keys(selected).map((key) => [key, selected[key]]);
            console.log(key_value_pair);
            
            setSelectedContact(key_value_pair);
            setContactTab(prev => {
                setActiveStep(prev + 1)
                return prev + 1
            });

        } else if (contactTab === 2){
            let session = JSON.parse(localStorage.getItem("ghl-keap"))
            // setContactTab(prev => prev + 1);
            let obj = {
                "locationId": session.ghl_token.locationId,
                "tags": []
                // "refresh_token": session.ghl_token.refresh_token
            };
            selectedContact.map((field, index) => {
                if(!keap_banned_fields.includes(field[0])){
                    var e = document.getElementById(field[0]);
                    var text = e && e.options && e.options[e.selectedIndex] ? e.options[e.selectedIndex].text : null;
                    if(field[0] === "email_addresses"){
                        obj[text] = field[1].length > 0 ? field[1][0].email : null;
                    } else if (field[0] === "addresses"){
                        obj[text] = field[1].length > 0 ? `${field[1][0].line1} ${field[1][0].line2} ${field[1][0].locality} ${field[1][0].postal_code} ${field[1][0].region}` : null;
                        
                        if(field[1][0] && field[1][0].postal_code){
                            obj["postalCode"] = field[1][0].postal_code;
                        }

                    } else if (field[0] === "phone_numbers"){
                        obj[text] = field[1].length > 0 ? field[1][0].number : null;
                    } else if (field[0] === "tag_ids"){
                        obj[text] = keapTags.tags.filter(tag => field[1].includes(tag.id)).map(tag => tag.name)
                    } else{
                        obj[text] = field[1]
                    }
                }
                if (field[0] === "id"){
                    let contactId = field[1];
                    obj["tags"] = [...obj["tags"], `keap_contact_id=${contactId}`]
                }
            })
            console.log(obj);

            const config = obj

            setUploadContactLoading(true);
            create_contact(config)
            .then((result) => {
                result = result.data;
                console.log(result);
                if(result.success){;
                    localStorage.setItem('ghl-keap', JSON.stringify({
                        ...session,
                        ghl_token: result.ghl_token,
                        keap_mapped_contacts: session.keap_mapped_contacts ? [result.contact, ...session.keap_mapped_contacts] : [result.contact]
                    }))

                    setMappedContacts(prev => {
                        if(prev && prev.length > 0){
                            return [result.contact, ...prev]
                        } else {
                            return [result.contact]
                        }
                    })

                    toast({
                        title: 'Contact Mapped!.',
                        description: "Keap Contact Mapped successfully.",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            })
            .catch(e => {
                console.log(e.message);
                toast({
                    title: 'Failed!.',
                    description: e.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            })
            .finally(() =>{
                setUploadContactLoading(false);
                onContactClose();
                setContactTab(0);
                setActiveStep(0);
                setSelectedContact(null);
            })

        } else {

        }
    }

    const changeContacts = (endpoint) => {
        let session = JSON.parse(localStorage.getItem("ghl-keap"));

        console.log(endpoint);

        const config = {
            locationId: session.ghl_token.locationId,
            keap_code: searchParams.get("code"),
            uri: window.location.origin + window.location.pathname,
            // refresh_token: session.keap_token.refresh_token,
            endpoint: endpoint
        }

        setFetchContactLoading(true);
        fetch_contacts(config)
        .then(async (result) => {
            result = result.data;
            console.log(result);
            if(result.success){
                localStorage.setItem('ghl-keap', JSON.stringify({
                    ...session,
                    keap_token: result.keap_token
                }))

                console.log(result.keap_contacts);

                try {
                    let filteredContacts = await result.keap_contacts.contacts.filter(contact => {
                        const email = contact.email_addresses[0].email;
    
                        return !mappedContacts.some(mapped_contact =>
                            mapped_contact.contact.email === email
                        );
                    });
                    result.keap_contacts.contacts = filteredContacts;
                    setKeapContacts(result);
                } catch (error) {
                    console.log(error);
                }
                
                
            }
        })
        .catch(e => {
            console.log(e.message);
        })
        .finally(() =>{
            setFetchContactLoading(false);
        })
    }

    const contactTabBack = () => {
        if(contactTab > 0) {
            setContactTab(prev => {
                setActiveStep(prev - 1)
                return prev - 1
            });
        }
    }

    const convertToCSV = (objArray) => {
        const array = [...objArray];
        const header = Object.keys(array[0]);
        const csv = [
            header.join(','),
            ...array.map(obj =>
                header.map(field => {
                    if(obj[field]){
                        if (field === 'tags' && Array.isArray(obj[field])) {
                            return obj[field].join(' ');
                        } else {
                            return JSON.stringify(obj[field]).replace(/,/g, ',');
                        }
                    }
                }).join(',')
            )
        ].join('\n');
        return csv;
    };
      

    const mapAllContacts = async () => {
        console.log(mapAllLink);

        let session = JSON.parse(localStorage.getItem("ghl-keap"))
        const config = {
            locationId: session.ghl_token.locationId,
            keap_code: searchParams.get("code"),
            uri: window.location.origin + window.location.pathname,
            endpoint: mapAllLink
        }

        let loading_payload = {
            locationId:  session.ghl_token.locationId,
            loading: true
        }
        setMapContactLoading(true);
        onContactClose();
        setContactTab(0);
        setActiveStep(0);
        setSelectedContact(null);

        try {
            await map_contact_loading(loading_payload);
            let result = await map_ghl_keap_contacts(config);
            if(result.data){
                result = result.data
                console.log(result);
                setMappedContacts(result.keap_mapped_contacts)
    
                toast({
                    title: 'Contacts Mapped!',
                    description: 'Keap Contacts Mapped successfully.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                if (result.code === 'ECONNABORTED') {
                    console.log('Request timed out.');
                    toast({
                        title: 'In Progress',
                        description: 'Mapping is in progress. Kindly check after some time.',
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    });
                } else {
                    console.log('Request error:', result);
                    toast({
                        title: 'Failed',
                        description: 'Error in mapping contacts.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                    setMapContactLoading(false);
                }
            }
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out. Check your server or internet connection.');
                toast({
                    title: 'In Progress',
                    description: 'Mapping is in progress. Kindly check after some time',
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                console.log('Error:', error);
                toast({
                    title: 'Error',
                    description: 'An error occurred while mapping contacts.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
                setMapContactLoading(false);
            }
        }
    }

    return (
        <Modal closeOnOverlayClick={false} onClose={onContactClose} size="4xl" isOpen={isContactOpen}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Map Contacts</ModalHeader>
                <ModalBody mb="5" position="relative">

                    <Box>
                        <Box border="1px solid #e0e0e0" p="14px">
                            <ModalStepper
                                steps={steps}
                                contactTab={contactTab}
                                activeStep={activeStep}
                            />
                        </Box>

                        <Box position="relative" height={fetchContactLoading ? "5rem" : "auto"}>
                            
                            {fetchContactLoading ? <Spinner size='xl' top="50%" left="50%" position="absolute" /> : <Box border="1px solid #e0e0e0" p="20px 10px 20px 10px" mt="5" display={contactTab === 0 ? "block" : "none"}>
                                <Text fontWeight="bold">Filters</Text>
                                <HStack>
                                    <Text>Get contacts from last: </Text>
                                    <Select id="filter-days" placeholder='Select option' w="50%">
                                        <option value='5' selected>5 days</option>
                                        <option value='10'>10 days</option>
                                        <option value='20'>20 days</option>
                                        <option value='30'>30 days</option>
                                        <option value='60'>60 days</option>
                                        <option value='120'>120 days</option>
                                        <option value='365'>365 days</option>
                                        <option value='730'>730 days</option>
                                        <option value='912'>912 days</option>
                                        <option value='1095'>1095 days</option>
                                        <option value='all'>All time</option>
                                    </Select>
                                </HStack>
                            </Box>}

                            {contactTab === 1 && <>
                                <Box border="1px solid #e0e0e0" p="20px 10px 20px 10px" mt="5" display={contactTab === 1 ? "block" : "none"}>
                                    <HStack w="100%">
                                        <Button onClick={() => changeContacts(keapContacts.keap_contacts.previous)}><ArrowBackIcon /></Button>
                                        <Spacer />
                                        <Button onClick={() => changeContacts(keapContacts.keap_contacts.next)}><ArrowForwardIcon /></Button>
                                    </HStack>
                                    {!fetchContactLoading && <TableContainer>
                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Contact Id</Th>
                                                    <Th>Email</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    keapContacts && keapContacts.keap_contacts.contacts && keapContacts.keap_contacts.contacts.map((contact, idx) => {
                                                        return (
                                                            <Tr key={idx} onClick={() => contactTabNext(contact)} cursor="pointer" _hover={{ bg: "#e8e8e8" }}>
                                                                <Td>{contact.id}</Td>
                                                                <Td>
                                                                    {contact && contact.email_addresses.length > 0 && contact.email_addresses[0].email}
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    })
                                                }
                                            </Tbody>
                                        </Table>
                                    </TableContainer>}
                                </Box>
                            </>}

                            {contactTab === 2 && <>
                                <Box border="1px solid #e0e0e0" p="20px 10px 20px 10px" mt="5" display={contactTab === 2 ? "block" : "none"}>
                                    <TableContainer>
                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Keap Field</Th>
                                                    <Th>Value</Th>
                                                    <Th>Map to GHL field</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {selectedContact && selectedContact.map((field, idx) => {
                                                    if (!keap_banned_fields.includes(field[0])){
                                                        return (
                                                            <Tr key={idx}>
                                                                <Td>{field[0]}</Td>
                                                                <Td>
                                                                    {
                                                                        field[0] === "email_addresses" ?
                                                                            field[1].length > 0 ? field[1][0].email : "-"
                                                                        : field[0] === "addresses" ?
                                                                            field[1].length > 0 ? `${field[1][0].line1}, ${field[1][0].line2}, ${field[1][0].locality}, ${field[1][0].postal_code}, ${field[1][0].region}` : "-"
                                                                        : field[0] === "phone_numbers" ?
                                                                            field[1].length > 0 ? field[1][0].number : "-"
                                                                        : field[0] === "tag_ids" ?
                                                                            field[1].length > 0 ?
                                                                                (keapTags.tags.filter(tag => field[1].includes(tag.id)).map(tag => tag.name)).join(", ") 
                                                                            : "-"
                                                                        : field[1] === null ? "-" : field[1]
                                                                    }
                                                                </Td>
                                                                <Td>
                                                                    {
                                                                        <>
                                                                            <Select placeholder='Select option' id={field[0]}>
                                                                                <option>firstName</option>
                                                                                <option>lastName</option>
                                                                                <option>name</option>
                                                                                <option>email</option>
                                                                                <option>locationId</option>
                                                                                <option>gender</option>
                                                                                <option>phone</option>
                                                                                <option>address1</option>
                                                                                <option>city</option>
                                                                                <option>state</option>
                                                                                <option>postalCode</option>
                                                                                <option>website</option>
                                                                                <option>timezone</option>
                                                                                <option>dnd</option>
                                                                                <option>tags</option>
                                                                                <option>customFields</option>
                                                                                <option>source</option>
                                                                                <option>country</option>
                                                                                <option>companyName</option>
                                                                            </Select>
                                                                        </>
                                                                    }
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    }
                                                })}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </>}

                        </Box>
                    </Box>

                </ModalBody>
                <Divider />
                <ModalFooter>
                    <HStack w="100%">
                        <Box>
                            <Button onClick={() => {
                                onContactClose();
                                setContactTab(0);
                                setActiveStep(0);
                            }} mr="3">Cancel</Button>
                            <Button mr="5" onClick={contactTabBack}>Back</Button>
                        </Box>
                        <Spacer />
                        <Button colorScheme='green' onClick={contactTabNext} isDisabled={contactTab === 1}>
                            {contactTab === 0 ? "Fetch Contacts" : contactTab === 2 ? uploadContactLoading ? <Spinner /> : "Upload Contact" : "Next"}
                        </Button>
                        {contactTab === 1 && <Button colorScheme='green' onClick={mapAllContacts}>
                            {mappAllLoading ? <Spinner /> : "Map all contacts"}
                        </Button>}
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
