import {
    Flex,
    Container,
    Heading,
    Stack,
    Text,
    Button,
    Icon,
    IconProps,
    Box,
    Spinner,
    useToast
} from '@chakra-ui/react'
import React, { useState } from 'react';
import { get_ghl_keap_creds } from '../apis/apiRequests';
import { useDispatch } from 'react-redux';
import { authActions } from '../redux/store';


export default function GhlLogin() {
    //hooks
    const toast = useToast();
    const dispatch = useDispatch();

    //states
    const [loading, setLoading] = useState(false);

    const connectGHL = () => {
        setLoading(true);
        var base_url = window.location.origin;
        var ghl_redirectUri = `${base_url}/connect/keap`;
        var ghl_clientID = process.env.REACT_APP_GHL_CLIENT_ID;
        window.location.href = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${ghl_redirectUri}&client_id=${ghl_clientID}&scope=contacts.readonly conversations/message.readonly locations.readonly contacts.write conversations/message.write locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write calendars.write calendars.readonly calendars/events.readonly calendars/events.write conversations.readonly conversations.write users.readonly users.write locations/tasks.readonly locations/tasks.write`;

        dispatch(authActions.ghlLogin());
    }

    return (
        <Box>
            <Container maxW={'5xl'}>
                <Stack
                    textAlign={'center'}
                    align={'center'}
                    spacing={{ base: 8, md: 10 }}
                    py={{ base: 20, md: 28 }}>
                    <Heading
                    fontWeight={600}
                    fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                    lineHeight={'110%'}
                >
                    Connect to{' '}
                    <Text as={'span'} color={'orange.400'}>
                        GoHighLevel
                    </Text>
                    </Heading>
                    <Text color={'gray.500'} maxW={'3xl'}>
                        Connecting to GoHighLevel allows you to leverage its powerful features and functionalities, enabling you to streamline your marketing, sales, and customer relationship management efforts all in one platform.
                    </Text>
                    <Stack spacing={6} direction={'row'}>
                        <Button
                            rounded={'full'}
                            px={6}
                            colorScheme={'orange'}
                            bg={'orange.400'}
                            _hover={{ bg: 'orange.500' }}
                            onClick={connectGHL}
                        >
                            {loading ? <Spinner /> : "Get started"}
                        </Button>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}
