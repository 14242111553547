import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Container,
    HStack,
    Heading,
    Input,
    Link,
    Select,
    Spacer,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast
} from '@chakra-ui/react';
import { get_orders, get_sub_accounts } from '../apis/apiRequests';
import { ArrowBackIcon, ArrowForwardIcon, PlusSquareIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';

export default function Orders() {
    //hooks
    const location = useLocation();
    const toast = useToast();
    
    //state variables
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState("");
    const [locationId, setLocationId] = useState(JSON.parse(localStorage.getItem("orders_location_id")) || "");
    const [ghlSubAccounts, setGhlSubAccounts] = useState([]);

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(false);

    //functions
    useEffect(() => {
        // const referrerPage = document.referrer;
        // console.log(referrerPage);
        // let top_url = document.referrer.split("/");

        // if(top_url[4] === "location"){
        //     setLocationId(top_url[5])
        // }
        // console.log(locationId);

        if(locationId !== "" && locationId !== null && locationId !== undefined){
            setLoading(true);
            setError(null);

            get_orders(currentPage, limit, email, locationId)
            .then(result => {
                if(result.data){
                    result = result.data;
                    console.log(result);

                    setOrders(result.data);
                    setTotalPages(result.totalPages);
                    setLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            })
        }
    }, [currentPage, limit, email, locationId]);

    // useEffect(() => {
    //     get_sub_accounts()
    //     .then(result => {
    //         result = result.data;
    //         setGhlSubAccounts(result.data);
    //         console.log(result.data);
    //     })
    //     .catch(error => {
    //         console.log(error);
    //         setError(error);
    //     })
    //     .finally(() => {
    //     })
    // }, [])

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleLimitChange = (e) => {
        console.log(e.target.value);
        const newLimit = Number(e.target.value);
        setLimit(newLimit)
    }

    const handleInputChange = (e) => {
        console.log(e.target.value);
        setEmail(e.target.value)
    }

    const handleAccountChange = (e) => {
        console.log(e.target.value);
        setLocationId(e.target.value);
        localStorage.setItem('orders_location_id', JSON.stringify(e.target.value));
    }

    const handleSaveLocatoinId = () => {
        let id = document.getElementById("locationId").value;
        console.log(id);
        if(id !== "" && id !== null && id !== undefined){
            setLocationId(id);
            localStorage.setItem('orders_location_id', JSON.stringify(id));

            toast({
                title: 'Success!',
                description: "Location Id saved successfully.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    return (
        <Box>
            <Container maxW='1200' padding="5">
                <HStack mb="10">
                    <Text fontSize="4xl" fontWeight="bold">Orders</Text>
                    <Spacer />
                    {/* <Select placeholder='Select GHL sub account' maxW="50%" value={locationId} onChange={handleAccountChange}>
                        {
                            ghlSubAccounts && ghlSubAccounts.map((account, idx) => {
                                return <option key={idx} value={account.locationId}>{account.name}</option>
                            })
                        }
                    </Select> */}
                    <HStack minW="50%">
                        <Input
                            placeholder='Enter GHL sub account location id'
                            id='locationId'
                            defaultValue={locationId}
                        />
                        <Button colorScheme='green' onClick={handleSaveLocatoinId}><PlusSquareIcon /></Button>
                    </HStack>
                </HStack>

                <HStack alignSelf="center" mb="10">
                    <HStack>
                        <Text>Search </Text>
                        <Input onChange={handleInputChange} placeholder='example@abc.com' />
                    </HStack>
                    <Spacer />
                    <HStack>
                        <Text>Show </Text>
                        <Select w="120px" placeholder='Select option' defaultValue={limit} onChange={handleLimitChange}>
                            <option value='5'>5</option>
                            <option value='10'>10</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                        </Select>
                        <Text>results per page</Text>
                    </HStack>
                </HStack>

                <HStack justifyContent="center" mb="5">
                    <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        colorScheme='facebook'
                    >
                        <ArrowBackIcon />
                    </Button>
                    <Spacer />
                    <Text>Page: {currentPage}/{totalPages}</Text>
                    <Spacer />
                    <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        colorScheme='facebook'
                    >
                        <ArrowForwardIcon />
                    </Button>
                </HStack>

                <TableContainer mb="10">
                    <Table variant='simple'>
                        <Thead bg="#f2f2f2">
                            <Tr>
                                <Th>Name</Th>
                                <Th>Email</Th>
                                <Th>Total Orders</Th>
                                <Th>Total Price ($)</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        {!loading && !error && <Tbody>
                            {orders && orders?.map((order, index) => {
                                return (
                                    <Tr key={index} _hover={{ bg: "#fafafa" }}>
                                        <Td>{order.contact.first_name} {order.contact.last_name}</Td>
                                        <Td>{order.contact.email}</Td>
                                        <Td>{order.total_orders}</Td>
                                        <Td>$ {(order.total_price).toLocaleString()}</Td>
                                        <Td><Link href={`/orders/view?email=${order.contact.email}`}>View</Link></Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>}
                    </Table>
                    {
                        !loading && orders.length < 1 && <Text textAlign="center" fontWeight="bold" mt="2">No available orders</Text>
                    }
                    {
                        loading && <Text textAlign="center" mt="5"><Spinner size="xl" /></Text>
                    }
                </TableContainer>
            </Container>
        </Box>
    )
}
