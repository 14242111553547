import axios from "axios";

const keap_base_url = `${process.env.REACT_APP_BACKEND_URL}/api/keap`;
const ghl_base_url = `${process.env.REACT_APP_BACKEND_URL}/api/ghl`;

//POST REQEUSTS
export const get_ghl_keap_creds = async (payload) => {
    try {
        let options = {
            method: "POST",
            url: `${ghl_base_url}/get_credentails`,
            data: payload
        }
        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const save_creds = async (payload) => {
    try {
        let options = {
            method: "POST",
            url: `${ghl_base_url}/save_creds`,
            data: payload
        }
        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const keap_auth = async (payload) => {
    try {
        let options = {
            method: 'POST',
            url: `${keap_base_url}/auth`,
            data: payload
        };
    
        const result = await axios(options);
        return result;
    } catch (error) {
        return error
    }
}

export const keap_submit = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${keap_base_url}/save_keap_cred`,
            data: payload
        }
        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const ghl_submit = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${ghl_base_url}/save_api`,
            data: payload
        }

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const fetch_keap_account = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${keap_base_url}/dashboard`,
            data: payload
        }

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const fetch_contacts = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${keap_base_url}/fetch_contacts`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const create_contact = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${ghl_base_url}/create_contact`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const fetch_emails = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${keap_base_url}/fetch_emails`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const create_email = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${ghl_base_url}/create_email`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const fetch_ghl_calendars = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${ghl_base_url}/get_calendars`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const create_appointment = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${ghl_base_url}/create_appointment`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const fetch_ghl_contacts = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${ghl_base_url}/get_contacts`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const fetch_appointments = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${keap_base_url}/fetch_appointments`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const fetch_keap_tasks = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${keap_base_url}/fetch_tasks`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const create_ghl_task = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${ghl_base_url}/create_tasks`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const check_keap_email = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${keap_base_url}/check_email`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const check_keap_appointment = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${keap_base_url}/check_appointment`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const create_convo_email = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${ghl_base_url}/check_conversation`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const get_all_contacts = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${keap_base_url}/get_all_contacts`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const map_all_contacts = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${ghl_base_url}/map_all_contacts`,
            data: payload
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const get_all_emails = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${keap_base_url}/get_all_emails`,
            data: payload,
            timeout: 60 * 1000
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const get_all_appointments = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${keap_base_url}/get_all_appointments`,
            data: payload,
            timeout: 60 * 1000
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const get_all_tasks = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${keap_base_url}/get_all_tasks`,
            data: payload,
            timeout: 60 * 1000
        };

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const map_contact_loading = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${ghl_base_url}/map_contact_loading`,
            data: payload
        }

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const map_email_loading = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${ghl_base_url}/map_email_loading`,
            data: payload
        }

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const map_appointment_loading = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${ghl_base_url}/map_appointment_loading`,
            data: payload
        }

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const map_task_loading = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${ghl_base_url}/map_task_loading`,
            data: payload
        }

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const map_ghl_keap_contacts = async (payload) => {
    try {
        const options = {
            method: 'POST',
            url: `${keap_base_url}/map_all_contacts`,
            data: payload,
            timeout: 60 * 1000
        }

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const get_orders = async (page, limit, email = "", locationId = "") => {
    try {
        const options = {
            method: 'GET',
            url: `${ghl_base_url}/orders?email=${email}&location_id=${locationId}&page=${page}&limit=${limit}`
        }

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}

export const get_sub_accounts = async () => {
    try {
        const options = {
            method: 'GET',
            url: `${ghl_base_url}/ghl_subaccounts`
        }

        const result = await axios(options);
        return result;
    } catch (error) {
        return error;
    }
}